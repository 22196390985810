<template>
    <h2>Analyse du verbe</h2>

    <div class="container-secondaire">
        <Etape-1 v-if="etape === 1" :etape="etape">
            <template v-slot:selection-verbe>
                <slot name="selection-verbe"></slot>
            </template>
        </Etape-1>

        <!-- <Etape-2 v-if="etape === 2" :etape="etape">

        </Etape-2> -->

        <Etape-3 v-if="etape === 2" :etape="etape" @updateVerbesValues="updateVerbesValues">

        </Etape-3>

        <Etape-4 v-if="etape === 3" :etape="etape" @updateVerbesValuesNegation="updateVerbesValuesNegation">

        </Etape-4>

        <Etape-5 v-if="etape === 4" :etape="etape" @updatePhraseHier="updatePhraseHier"
            @updatePhraseAuj="updatePhraseAuj" @updatePhraseDemain="updatePhraseDemain">
        </Etape-5>

        <Etape-6 v-if="etape === 5" :etape="etape" @updateTypeVerbe="updateTypeVerbe">
        </Etape-6>

        <div ref="recap_verbe">
            <LeRecapitulatif v-if="etape === 6">

            </LeRecapitulatif>
        </div>



        <div v-if="etape <= 6" class="button-container">
            <the-button v-if="etape === 1" buttonType="annuler" @click="annuler">Annuler</the-button>
            <the-button v-if="etape === 1" buttonType="valider" @click="valider">Valider le verbe</the-button>
            <the-button buttonType="suivant" @click="confirmer">Suivant</the-button>
        </div>

    </div>

    <v-container v-if="etape === 6">
        <v-row align="center" justify="center">
            <the-button buttonType="telecharger" @click="showDialog = true">Télécharger</the-button>
        </v-row>

        <v-dialog v-model="showDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Informations à entrer</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field v-model="prenom" label="Prénom" :rules="[rules.required]" required></v-text-field>
                        <v-text-field v-model="nom" label="Nom" :rules="[rules.required]" required></v-text-field>
                        <v-text-field v-model="classe" label="Classe" :rules="[rules.required]" required></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="telecharger" :disabled="!valid">Télécharger</v-btn>
                    <v-btn @click="showDialog = false">Annuler</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
import Etape1 from './Etape-1.vue'
// import Etape2 from './Etape-2.vue'
import Etape3 from './Etape-3.vue'
import Etape4 from './Etape-4.vue'
import Etape5 from './Etape-5.vue'
import Etape6 from './Etape-6.vue'
import LeRecapitulatif from './LeRecapitulatif.vue'
import html2canvas from 'html2canvas';

export default {
    emits: ['annuler', 'valider', 'confirmerEtape1', 'confirmerEtape2', 'finAnalyseVerbe', 'annuler-verbe-principal'],

    provide()
    {
        return {
            getVerbesValues: () => this.verbesValues, // si on passe directement l'objet, il n'est pas maj (voir : https://stackoverflow.com/questions/60416153/making-vue-js-provide-inject-reactive)
            getVerbesValuesNegation: () => this.verbeValuesNegation,
            getPhraseHier: () => this.phraseHier,
            getPhraseAuj: () => this.phraseAuj,
            getPhraseDemain: () => this.phraseDemain,
            getTypeVerbe: () => this.typeVerbe
        }
    },

    components: {
        Etape1,
        // Etape2,
        Etape3,
        Etape4,
        Etape5,
        Etape6,
        LeRecapitulatif
    },

    data()
    {
        return {
            etape: 1,
            verbesValues: {},
            verbeValuesNegation: {},
            phraseHier: "",
            phraseAuj: "",
            phraseDemain: "",
            typeVerbe: "",
            showDialog: false,
            prenom: "",
            nom: "",
            classe: "",
            valid: false,
            rules: {
                required: value => !!value || 'Ce champ est requis.',
            },
        }
    },

    methods: {
        annuler()
        {
            if (this.etape === 2)
            {
                this.$emit('annuler-verbe-principal')
            }
            else
            {
                this.$emit('annuler')
            }
        },

        valider()
        {
            this.$emit('valider');
        },

        confirmer()
        {
            if (this.etape === 1)
            {
                this.$emit('confirmerEtape1');
            }
            if (this.etape === 2)
            {
                this.$emit('confirmerEtape2');
            }
            if (this.etape === 6)
            {
                this.$emit('finAnalyseVerbe');
            }

            this.etape += 1;
        },

        updateVerbesValues(objet)
        {
            this.verbesValues = objet;
        },

        updateVerbesValuesNegation(objet)
        {
            this.verbeValuesNegation = objet;
        },

        updatePhraseHier(phrase)
        {
            this.phraseHier = "Hier, " + phrase
        },

        updatePhraseAuj(phrase)
        {
            this.phraseAuj = "Aujourd'hui, " + phrase
        },

        updatePhraseDemain(phrase)
        {
            this.phraseDemain = "Demain, " + phrase
        },

        updateTypeVerbe(typeVerbe)
        {
            this.typeVerbe = typeVerbe;
        },

        telecharger()
        {
            // Code pour télécharger l'image ici
            const recapElement = this.$refs.recap_verbe;
            const recapWithName = document.createElement('div');
            recapWithName.innerHTML = `
        <h3>${this.prenom} ${this.nom} <br> Classe: ${this.classe}</h3> <br> <br>
        ${recapElement.innerHTML}
      `;
            document.body.appendChild(recapWithName);

            html2canvas(recapWithName).then(canvas =>
            {
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = 'recapitulatif_verbe.png';
                link.click();

                // Nettoyer le DOM
                document.body.removeChild(recapWithName);
            });

            // Fermer la boîte de dialogue après le téléchargement
            this.showDialog = false;
        },
    },
}
</script>






<style scoped>
h2 {
    margin-left: 1rem;
}

li {
    font-weight: bold;
}


.button-container {
    /* pour que les deux buttons soient côte a côte */
    display: flex;
    justify-content: center;
}
</style>