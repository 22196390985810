<template>
  <h2>Analyse des fonctions syntaxiques</h2>

  <div v-if="etape === 1" class="container-secondaire">
    <Etape-1 @sujetValidé="sujetValide" @sujetAnnulé="sujetAnnule" @finEtape="incrementeEtape1"
      :verbe-principal="verbePrincipal" :liste-mots="listeMots"></Etape-1>
  </div>

  <div v-if="etape === 2" class="container-secondaire">
    <Etape-2 @codValidé="codValide" @codAnnulé="codAnnule" :liste-mots="listeSujetRegroupe"
      :verbe-principal="verbePrincipal" :sujet-principal="sujetPrincipal" @finEtape="incrementeEtape2"></Etape-2>
  </div>

  <div v-if="etape === 3" class="container-secondaire">
    <Etape-3 @coiValidé="coiValide" @coiAnnulé="coiAnnule" :liste-mots="listeSujetRegroupe"
      :verbe-principal="verbePrincipal" :sujet-principal="sujetPrincipal" @finEtape="incrementeEtape3"></Etape-3>
  </div>

  <div v-if="etape === 4" class="container-secondaire">
    <Etape-4 @complémentValidé="complementValide" @complémentAnnulé="complementAnnule"
      @finEtape="incrementeEtape4"></Etape-4>
  </div>

  <div v-if="etape === 5" class="container-secondaire">
    <div ref="recap_verbe">
      <RecapitulatifAnalyseFS> </RecapitulatifAnalyseFS>
    </div>
  </div>

  <v-container v-if="etape === 5">
    <v-row align="center" justify="center">
      <the-button buttonType="telecharger" @click="showDialog = true">Télécharger</the-button>
    </v-row>

    <v-dialog v-model="showDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Informations à entrer</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="prenom" label="Prénom" :rules="[rules.required]" required></v-text-field>
            <v-text-field v-model="nom" label="Nom" :rules="[rules.required]" required></v-text-field>
            <v-text-field v-model="classe" label="Classe" :rules="[rules.required]" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="telecharger" :disabled="!valid">Télécharger</v-btn>
          <v-btn @click="showDialog = false">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Etape1 from "./Etape-1.vue";
import Etape2 from "./Etape-2.vue";
import Etape3 from "./Etape-3.vue";
import Etape4 from "./Etape-4.vue";
import RecapitulatifAnalyseFS from "./RecapitulatifAnalyseFS.vue";
import html2canvas from 'html2canvas';

export default {
  emits: [
    "sujetValidé",
    "sujetAnnulé",
    "codValidé",
    "codAnnulé",
    "coiValidé",
    "coiAnnulé",
    "complémentValidé",
    "complémentAnnulé",
  ],

  props: ["verbePrincipal", "sujetPrincipal", "liste-mots", "cod", "coi", "complement"],

  components: {
    Etape1,
    Etape2,
    Etape3,
    Etape4,
    RecapitulatifAnalyseFS,
  },

  provide()
  {
    return {
      getVerbePrincipal: () => this.verbePrincipal,
      getSujetPrincipal: () => this.sujetPrincipal,
      getCOD: () => this.cod,
      getCOI: () => this.coi,
      getComplement: () => this.complement
    };
  },

  data()
  {
    return {
      etape: 1,
      listeSujetRegroupe: [],
      listeCodRegroupe: [],
      showDialog: false,
      prenom: "",
      nom: "",
      classe: "",
      valid: false,
      rules: {
        required: value => !!value || 'Ce champ est requis.',
      },
    };
  },

  methods: {
    // Etape 1 - Sujet
    sujetValide(sujetSelectionne)
    {
      this.$emit("sujetValidé", sujetSelectionne);
    },

    sujetAnnule(sujetSelectionne)
    {
      this.$emit("sujetAnnulé", sujetSelectionne);
    },

    incrementeEtape1(listeSujetRegroupe)
    {
      this.listeSujetRegroupe = listeSujetRegroupe;
      this.etape += 1;
    },

    // Etape 2 - Cod
    codValide(codSelectionne)
    {
      this.$emit("codValidé", codSelectionne);
    },

    codAnnule(codSelectionne)
    {
      this.$emit("codAnnulé", codSelectionne);
    },

    incrementeEtape2(listeCodRegroupe)
    {
      this.listeCodRegroupe = listeCodRegroupe;
      this.etape += 1;
    },

    // Etape 2 - Coi
    coiValide(coiSelectionne)
    {
      this.$emit("coiValidé", coiSelectionne);
    },

    coiAnnule(coiSelectionne)
    {
      this.$emit("coiAnnulé", coiSelectionne);
    },

    incrementeEtape3(listeCoiRegroupe)
    {
      this.listeCodRegroupe = listeCoiRegroupe;
      this.etape += 1;
    },

    // Etape 4 - Compléments circonstanciels

    complementValide(texteSelectionne)
    {
      this.$emit("complémentValidé", texteSelectionne);
    },

    complementAnnule(texteSelectionne)
    {
      this.$emit("complémentAnnulé", texteSelectionne);
    },

    incrementeEtape4()
    {
      this.etape += 1;
    },

    telecharger()
    {
      // Code pour télécharger l'image ici
      const recapElement = this.$refs.recap_verbe;
      const recapWithName = document.createElement('div');
      recapWithName.innerHTML = `
        <h3>${this.prenom} ${this.nom} <br> Classe: ${this.classe}</h3> <br> <br>
        ${recapElement.innerHTML}
      `;
      document.body.appendChild(recapWithName);

      html2canvas(recapWithName).then(canvas =>
      {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'recapitulatif_fonctions.png';
        link.click();

        // Nettoyer le DOM
        document.body.removeChild(recapWithName);
      });

      // Fermer la boîte de dialogue après le téléchargement
      this.showDialog = false;
    },
  },
};
</script>

<style scoped>
h2 {
  margin-left: 1rem;
}

.container-secondaire {
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  position: relative;
}
</style>
