<template>
  <h2>Analyse des classes grammaticales</h2>


  <div v-if="etape === 1" class="container-secondaire">
    <Etape-1 @nomValidé="nomValide" @nomAnnulé="nomAnnule" @determinantValidé="determinantValide"
      @determinantAnnulé="determinantAnnule" @finEtape="incrementeEtape1"></Etape-1>
  </div>

  <div v-if="etape === 2" class="container-secondaire">
    <Etape-2 @updateDeterminantsValues="updateDeterminantsValues" @finEtape="incrementeEtape2"></Etape-2>
  </div>

  <div v-if="etape === 3" class="container-secondaire">
    <Etape-3 @adjectifValidé="adjectifValide" @adjectifAnnulé="adjectifAnnule" @typeAdjectifValidé="typeAdjectifValide"
      @finEtape="incrementeEtape3"></Etape-3>
  </div>

  <div v-if="etape === 4" class="container-secondaire">
    <Etape-4 @pronomValidé="pronomValide" @pronomAnnulé="pronomAnnule" @finEtape="incrementeEtape4"></Etape-4>
  </div>

  <div v-if="etape === 5" class="container-secondaire">
    <Etape-5 @conjonctionCoordValidé="conjonctionCoordValide" @conjonctionCoordAnnulé="conjonctionCoordAnnule"
      @finEtape="incrementeEtape5"></Etape-5>
  </div>

  <div v-if="etape === 6" class="container-secondaire">
    <Etape-6 @finEtape="incrementeEtape6" @conjonctionSubValidé="conjonctionSubValide"
      @conjonctionSubAnnulé="conjonctionSubAnnule"></Etape-6>
  </div>

  <div v-if="etape === 7" class="container-secondaire">
    <Etape-7 @prepositionValidé="prepositionValide" @prepositionAnnulé="prepositionAnnule"
      @finEtape="incrementeEtape7"></Etape-7>
  </div>

  <div v-if="etape === 8" class="container-secondaire">
    <Etape-8 @adverbeValidé="adverbeValide" @adverbeAnnulé="adverbeAnnule" @finEtape="incrementeEtape8"> </Etape-8>
  </div>

  <div v-if="etape === 9" class="container-secondaire">
    <div ref="recap_verbe">
      <RecapitulatifAnalyseCG>

      </RecapitulatifAnalyseCG>
    </div>
  </div>

  <v-container v-if="etape === 9">
    <v-row align="center" justify="center">
      <the-button buttonType="telecharger" @click="showDialog = true">Télécharger</the-button>
    </v-row>

    <v-dialog v-model="showDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Informations à entrer</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="prenom" label="Prénom" :rules="[rules.required]" required></v-text-field>
            <v-text-field v-model="nom" label="Nom" :rules="[rules.required]" required></v-text-field>
            <v-text-field v-model="classe" label="Classe" :rules="[rules.required]" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="telecharger" :disabled="!valid">Télécharger</v-btn>
          <v-btn @click="showDialog = false">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Etape1 from "./Etape-1.vue";
import Etape2 from "./Etape-2.vue";
import Etape3 from "./Etape-3.vue";
import Etape4 from "./Etape-4.vue";
import Etape5 from "./Etape-5.vue";
import Etape6 from "./Etape-6.vue";
import Etape7 from "./Etape-7.vue";
import Etape8 from "./Etape-8.vue";
import RecapitulatifAnalyseCG from "./RecapitulatifAnalyseCG.vue";
import html2canvas from 'html2canvas';


export default {
  emits: ["nomValidé", "nomAnnulé", "determinantValidé", "determinantAnnulé", "adjectifValidé", "adjectifAnnulé", "typeAdjectifValidé", "pronomValidé", "pronomAnnulé", "conjonctionCoordValidé", "conjonctionCoordAnnulé", 'conjonctionSubValidé', 'conjonctionSubAnnulé', 'prepositionValidé', 'prepositionAnnulé', 'adverbeValidé', 'adverbeAnnulé'],

  provide()
  {
    return {
      getDeterminantsValues: () => this.determinantsValues
    }
  },

  inject: ["getDeterminantSelectionne"],

  components: {
    Etape1,
    Etape2,
    Etape3,
    Etape4,
    Etape5,
    Etape6,
    Etape7,
    Etape8,
    RecapitulatifAnalyseCG
  },

  data()
  {
    return {
      etape: 1,
      determinantsValues: {},
      showDialog: false,
      prenom: "",
      nom: "",
      classe: "",
      valid: false,
      rules: {
        required: value => !!value || 'Ce champ est requis.',
      },
    };
  },

  methods: {
    // Etape 1 - Nom
    nomValide(nomSelectionne)
    {
      this.$emit("nomValidé", nomSelectionne);
    },

    nomAnnule(nomSelectionne)
    {
      this.$emit("nomAnnulé", nomSelectionne);
    },

    determinantValide(determinantSelectionne)
    {
      this.$emit("determinantValidé", determinantSelectionne);
    },

    determinantAnnule(determinantSelectionne)
    {
      this.$emit("determinantAnnulé", determinantSelectionne);
    },

    adjectifValide(adjectifSelectionne)
    {
      this.$emit("adjectifValidé", adjectifSelectionne);
    },

    adjectifAnnule(adjectifSelectionne)
    {
      this.$emit("adjectifAnnulé", adjectifSelectionne);
    },

    typeAdjectifValide(adjectifSelectionne, adjectifQualifiant)
    {
      this.$emit('typeAdjectifValidé', adjectifSelectionne, adjectifQualifiant);
    },

    pronomValide(pronomSelectionne)
    {
      this.$emit("pronomValidé", pronomSelectionne);
    },

    pronomAnnule(pronomSelectionne)
    {
      this.$emit("pronomAnnulé", pronomSelectionne);
    },

    conjonctionCoordValide(conjonctionCoordSelectionne)
    {
      this.$emit("conjonctionCoordValidé", conjonctionCoordSelectionne);
    },

    conjonctionCoordAnnule(conjonctionCoordSelectionne)
    {
      this.$emit("conjonctionCoordAnnulé", conjonctionCoordSelectionne);
    },

    conjonctionSubValide(conjonctionSubSelectionne)
    {
      this.$emit("conjonctionSubValidé", conjonctionSubSelectionne);
    },

    conjonctionSubAnnule(conjonctionSubSelectionne)
    {
      this.$emit("conjonctionSubAnnulé", conjonctionSubSelectionne);
    },

    prepositionValide(prepositionSelectionnee)
    {
      this.$emit("prepositionValidé", prepositionSelectionnee);
    },

    prepositionAnnule(prepositionSelectionnee)
    {
      this.$emit("prepositionAnnulé", prepositionSelectionnee);
    },

    adverbeValide(adverbeSelectionne)
    {
      this.$emit("adverbeValidé", adverbeSelectionne);
    },

    adverbeAnnule(adverbeSelectionne)
    {
      this.$emit("adverbeAnnulé", adverbeSelectionne);
    },

    incrementeEtape1()
    {
      if (this.getDeterminantSelectionne().length != 0)
      {
        this.etape += 1;
      }
      else
      {
        this.etape += 2;
      }
    },

    incrementeEtape2()
    {
      this.etape += 1;
    },

    incrementeEtape3()
    {
      this.etape += 1;
    },

    incrementeEtape4()
    {
      this.etape += 1;
    },

    incrementeEtape5()
    {
      this.etape += 1;
    },
    incrementeEtape6()
    {
      this.etape += 1;
    },
    incrementeEtape7()
    {
      this.etape += 1;
    },
    incrementeEtape8()
    {
      this.etape += 1;
    },

    updateDeterminantsValues(objet)
    {
      this.determinantsValues = objet;
    },

    telecharger()
    {
      // Code pour télécharger l'image ici
      const recapElement = this.$refs.recap_verbe;
      const recapWithName = document.createElement('div');
      recapWithName.innerHTML = `
        <h3>${this.prenom} ${this.nom} <br> Classe: ${this.classe}</h3> <br> <br>
        ${recapElement.innerHTML}
      `;
      document.body.appendChild(recapWithName);

      html2canvas(recapWithName).then(canvas =>
      {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'recapitulatif_classes.png';
        link.click();

        // Nettoyer le DOM
        document.body.removeChild(recapWithName);
      });

      // Fermer la boîte de dialogue après le téléchargement
      this.showDialog = false;
    },

  }

}
</script>


<style>
h2 {
  margin-left: 1rem;
}

.container-secondaire {
  max-width: 85%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  position: relative;
}

.hr-style {
  margin: 0;
  /* Supprime la marge par défaut du hr */
  border-top: 1px solid #ccc;
  /* Ajoute une bordure personnalisée */
  width: calc(100% - 5rem);
  /* Ajustez la largeur du hr en fonction de vos besoins */
}

.invalide {
  color: red;
}
</style>