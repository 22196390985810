<template>
    <p class="consigne">1 - Sélectionnez une préposition.</p>

    <p>
        Préposition sélectionnée : <span class="preposition">{{ prepositionSelectionnee }}</span>
    </p>

    <div v-if="etape === 2">
        <hr class="hr-style" />
        <div class="form-control">
            <p class="consigne" :class="{ invalide: choix === 'en-attente' }">2 - Y a-t-il d'autres prépositions à
                analyser
                ?
            </p>
            <v-radio-group v-model="choix" row :class="{ invalide: choix === 'en-attente' }">
                <v-radio id="oui_choix" label="Oui" value="oui"></v-radio>
                <v-radio id="non_choix" label="Non" value="non"></v-radio>
            </v-radio-group>
        </div>
    </div>

    <div class="button-container">
        <the-button v-if="afficheValider && etape == 1" buttonType="valider" @click="validation">Valider</the-button>
        <the-button v-else-if="etape == 1" buttonType="annuler" @click="annulation">Annuler</the-button>
        <the-button buttonType="suivant_short" @click="suivant">Suivant</the-button>
        <the-button buttonType="passer" @click="passer">Passer</the-button>
    </div>
</template>

<script>
import Utility from '../../mixins/utility.js'

export default
    {
        mixins: [Utility],

        emits: ['prepositionValidé', 'prepositionAnnulé', 'fin-etape'],

        data()
        {
            return {
                prepositionSelectionnee: "",
                afficheValider: true,
                etape: 1,
                choix: null
            }
        },

        methods:
        {
            sendPrepositionInformations()
            {
                const prepositionInformation =
                {
                    motPreposition: this.prepositionSelectionnee,
                };

                this.$store.dispatch('updatePrepositionList', prepositionInformation);
            },

            validation()
            {
                this.afficherValider = false;

                this.prepositionSelectionnee = this.getHighlightedText();
                this.$emit("prepositionValidé", this.prepositionSelectionnee);

            },

            annulation()
            {
                this.afficherValider = true;

                this.$emit("prepositionAnnulé", this.prepositionSelectionnee);
                this.prepositionSelectionnee = "";

            },

            suivant()
            {
                if (this.etape < 2)
                {
                    this.etape++;
                }

                else if (this.etape == 2)
                {
                    if (this.choix === 'non')
                    {
                        this.sendPrepositionInformations();
                        this.$emit("fin-etape");
                    }
                    else if (this.choix === 'oui')
                    {
                        this.sendPrepositionInformations();
                        this.conjonctionSubSelectionne = "";
                        this.afficheValider = true;
                        this.choix = null;
                        this.etape = 1;
                    }
                    else if (this.choix === null)
                    {
                        this.choix = 'en-attente'
                    }
                }
            },

            passer()
            {
                this.$emit("prepositionAnnulé", this.prepositionSelectionnee);
                this.$emit("fin-etape");
            },
        }
    }
</script>

<style scoped>
.preposition {
    text-decoration: underline double;
}
</style>