<template>
  <h2 class="mb-5"> <v-icon icon="fa:fas fa-book" />Récapitulatif</h2>

  <div> <!-- Sujet -->
    <h3>Sujet du verbe principal</h3>

    <div v-if="!getSujetPrincipal()">
      Vous n'avez pas sélectionné de sujet.
    </div>

    <div v-else>

      Le sujet du verbe principal «<span class="verbe-principal">{{ getVerbePrincipal() }}</span>» est «<span
        class="sujet">{{ getSujetPrincipal() }}</span>».
    </div>
  </div>

  <div> <!-- COD -->
    <h3>Complément d'objet direct</h3>

    <div v-if="!getCOD()">
      Vous n'avez pas sélectionné de COD.
    </div>

    <div v-else>

      Le COD est «<span class="cod">{{ getCOD() }}</span>».
    </div>
  </div>

  <div> <!-- COI -->
    <h3>Complément d'objet indirect</h3>

    <div v-if="!getCOI()">
      Vous n'avez pas sélectionné de COI.
    </div>

    <div v-else>
      Le COI est «<span class="coi">{{ getCOI() }}</span>».
    </div>
  </div>

  <div> <!-- Compléments circonstanciels -->
    <h3>Compléments circonstanciels</h3>

    <div v-if="getComplement().length == 0">
      Vous n'avez pas sélectionné de complément circonstanciel.
    </div>

    <div v-else>
      Les compléments circonstanciels sont les suivants :
      <ul>
        <li v-for="complement in getComplement()" :key="complement" class="complement">
          {{ complement }}
        </li>
      </ul>
    </div>
  </div>


</template>

<script>
export default {
  inject: ['getSujetPrincipal', 'getVerbePrincipal', 'getCOD', 'getCOI', 'getComplement'],
}
</script>



<style scoped>
div {
  margin-top: 1rem;
}

.verbe-principal {
  color: blue;
  font-weight: bold;
}

.sujet {
  color: red;
}

.cod {
  color: green;
}

.coi {
  color: rgb(151, 108, 13);
}

.complement {
  color: blueviolet;
}

label {
  float: left;
  clear: left;
  width: 60px;
  text-align: right;
  margin: 1rem;
}

input {
  float: left;
  margin: 1rem;
  width: 50%;
}

.souligne {
  text-decoration: underline;
}
</style>