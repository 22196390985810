<template>
    <p class="consigne">1 - Sélectionnez un pronom.</p>

    <p>
        Pronom sélectionné : <span class="pronom">{{ pronomSelectionne }}</span>
    </p>

    <div v-if="etape >= 2">
        <hr class="hr-style mt-2 mb-5" />
        <p class="consigne">2 - Remplacez le par un autre pronom.</p>
        <v-col>
            <v-text-field :label="pronomSelectionne" v-model="pronomRemplacant" density="compact" variant="solo"
                :loading="!pronomRemplacant" />
        </v-col>
    </div>

    <div v-if="etape >= 3">
        <hr class="hr-style mt-2 mb-5" />
        <p class="consigne">3 - Par quel nom ou groupe nominal pourriez-vous le remplacer ?</p>
        <v-col>
            <v-text-field :label="pronomSelectionne" v-model="phraseRemplacante" density="compact" variant="solo"
                :loading="!phraseRemplacante" />
        </v-col>
    </div>

    <div v-if="etape == 4">
        <hr class="hr-style" />
        <div class="form-control">
            <p class="consigne" :class="{ invalide: choix === 'en-attente' }">4 - Y a-t-il d'autres pronoms à analyser
                ?
            </p>
            <v-radio-group v-model="choix" row :class="{ invalide: choix === 'en-attente' }">
                <v-radio id="oui_choix" label="Oui" value="oui"></v-radio>
                <v-radio id="non_choix" label="Non" value="non"></v-radio>
            </v-radio-group>
        </div>
    </div>

    <div class="button-container">
        <the-button v-if="afficheValider && etape == 1" buttonType="valider" @click="validation">Valider</the-button>
        <the-button v-else-if="etape == 1" buttonType="annuler" @click="annulation">Annuler</the-button>
        <the-button buttonType="suivant_short" @click="suivant">Suivant</the-button>
        <the-button buttonType="passer" @click="passer">Passer</the-button>
    </div>
</template>

<script>
import Utility from '../../mixins/utility.js'

export default
    {
        mixins: [Utility],

        emits: ["pronomValidé", "pronomAnnulé", "fin-etape"],

        data()
        {
            return {

                afficheValider: true,
                pronomSelectionne: "",
                pronomRemplacant: "",
                phraseRemplacante: "",
                choix: null,
                etape: 1
            }
        },

        methods:
        {
            validation()
            {
                this.afficheValider = false;

                if (this.etape == 1)
                {
                    this.pronomSelectionne = this.getHighlightedText();
                    this.$emit("pronomValidé", this.pronomSelectionne);
                }
            },

            annulation()
            {
                this.afficheValider = true;

                if (this.etape == 1)
                {
                    this.$emit("pronomAnnulé", this.pronomSelectionne);
                    this.pronomSelectionne = "";
                }

            },

            suivant()
            {
                if (this.etape < 4)
                {
                    this.etape++;
                }
                else if (this.etape === 4)
                {
                    if (this.choix === 'non')
                    {
                        this.sendPronomInformations();
                        this.$emit("fin-etape");
                    }
                    else if (this.choix === 'oui')
                    {
                        this.sendPronomInformations();
                        this.pronomSelectionne = "";
                        this.pronomRemplacant = "";
                        this.phraseRemplacante = "";
                        this.afficheValider = true;
                        this.choix = null;
                        this.etape = 1;
                    }
                    else if (this.choix === null)
                    {
                        this.choix = 'en-attente'
                    }
                }
            },

            passer()
            {
                this.$emit("pronomAnnulé", this.pronomSelectionne);
                this.$emit("fin-etape");
            },

            sendPronomInformations()
            {
                console.log("Entrée dans sendPronomInformations");
                const pronomInformation =
                {
                    motPronom: this.pronomSelectionne,
                    pronomRemplacant: this.pronomRemplacant,
                    phraseRemplacante: this.phraseRemplacante
                };


                this.$store.dispatch('updatePronomsList', pronomInformation);
            }
        }
    }
</script>









<style scoped>
.pronom {
    color: red;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.v-text-field {
    width: 40%
}

.v-text-field :deep(label) {
    color: red;
    /* background-color: orange; */
    opacity: 100;
}
</style>