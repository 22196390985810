<template>
  <p class="consigne">{{ etape }} - Mettez le verbe entre « ne…pas ».</p>

  <div class="container">
    <span v-for="mot in listeSansVerbe" :key="mot">
      {{ afficheAvecEspace(mot) }}
    </span>
  </div>

  <div v-for="(verbe, indice) in verbesSelectionnes" :key="indice" class="listeVerbes">
    <v-col>
      <v-text-field v-model="verbesValues[verbe]" density="compact" variant="solo" :loading="!verbesValues[verbe]">
        <template v-slot:label>
          <span :class="getVerbeClasses">{{ verbe }}</span>
        </template>
      </v-text-field>
    </v-col>
  </div>

</template>

<script>
export default {
  props: ['etape'],

  emits: ['updateVerbesValuesNegation'],

  inject: ["verbesSelectionnes", "listeMots", "listeMotsRegroupes"],

  data()
  {
    return {
      verbesValues: {}
    }
  },

  watch: {
    verbesValues: {
      handler(newValue)
      {
        this.$emit('updateVerbesValuesNegation', newValue)
      },
      deep: true // à utiliser quand on veut surveiller un objet, sinon on ne passe jamais dans le watch
    }
  },

  computed:
  {
    listeSansVerbe()
    {
      return this.listeMotsRegroupes().map((element) => (this.verbesSelectionnes.includes(element) ? this.verbesValues[element] || "_____" : element));
    },

    getVerbeClasses()
    {
      return this.$store.getters['styles/getVerbeClasses']
    },
  },

  methods: {
    afficheAvecEspace(mot)
    {
      if (mot === " ") { return "\u00a0" }
      else { return mot }
    }
  }
}
</script>






<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

/* .verbe {
  color: blue;
  margin-right: 1rem;
} */

input {
  position: absolute;
  margin-left: 15rem;
}

.listeVerbes {
  display: flex;
  padding: 1px;
}

input[type="text"] {
  border: 1px solid #ccc;
  padding: 0px;
  background-color: white;
  color: black;
}

.v-text-field {
  width: 20%
}
</style>