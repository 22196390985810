<template>
    <p class="consigne">1 - Sélectionnez un adjectif.</p>

    <p>
        Adjectif sélectionné : <span class="adjectif">{{ adjectifSelectionne }}</span>
    </p>

    <div v-if="etape >= 2">
        <hr class="hr-style mt-2 mb-5" />
        <p class="consigne">2 - Peut-on ajouter "très" devant l'adjectif ?</p>
        <div class="form-control">
            <v-radio-group v-model="adjectifQualifiant" row>
                <template v-slot:label>
                    <p>très <span class="adjectif">{{ this.adjectifSelectionne }}</span></p>
                </template>
                <v-radio id="oui" label="oui" :value=true></v-radio>
                <v-radio id="non" label="non" :value=false></v-radio>
            </v-radio-group>
        </div>
        <p v-if="adjectifQualifiant != null">L'adjectif est donc un adjectif <strong>{{ adjectifQualifiant ?
            "qualifiant" : "classifiant" }}</strong>.
        </p>
    </div>

    <div v-if="etape === 3">
        <hr class="hr-style" />
        <div class="form-control">
            <p class="consigne" :class="{ invalide: choix === 'en-attente' }">3 - Y a-t-il d'autres adjectifs à analyser
                ?
            </p>
            <v-radio-group v-model="choix" row :class="{ invalide: choix === 'en-attente' }">
                <v-radio id="oui_choix" label="Oui" value="oui"></v-radio>
                <v-radio id="non_choix" label="Non" value="non"></v-radio>
            </v-radio-group>
        </div>
    </div>

    <div class="button-container">
        <the-button v-if="afficheValider && this.etape == 1" buttonType="valider"
            @click="validation">Valider</the-button>
        <the-button v-else-if="this.etape == 1" buttonType="annuler" @click="annulation">Annuler</the-button>
        <the-button buttonType="suivant_short" @click="suivant">Suivant</the-button>
        <the-button buttonType="passer" @click="passer">Passer</the-button>
    </div>
</template>

<script>
import TheButton from '../UI/TheButton.vue';
import Utility from '../../mixins/utility.js'

export default
    {
        mixins: [Utility],

        emits: ["adjectifValidé", "adjectifAnnulé", "fin-etape", "typeAdjectifValidé"],

        components:
        {
            TheButton
        },

        data()
        {
            return {
                etape: 1,
                adjectifSelectionne: "",
                afficheValider: true,
                adjectifQualifiant: null,
                choix: null
            }
        },

        methods:
        {
            validation()
            {
                this.afficheValider = false;

                if (this.etape == 1)
                {
                    this.adjectifSelectionne = this.getHighlightedText();
                    this.$emit("adjectifValidé", this.adjectifSelectionne);
                }
            },

            annulation()
            {
                this.afficheValider = true;

                if (this.etape == 1)
                {
                    this.$emit("adjectifAnnulé", this.adjectifSelectionne);
                    this.adjectifSelectionne = "";
                }
            },

            suivant()
            {
                if (this.etape == 1)
                {
                    this.etape++;
                }
                else if (this.etape == 2)
                {
                    this.etape++;
                }
                else if (this.etape === 3)
                {
                    if (this.choix === 'non')
                    {
                        this.$emit("fin-etape");
                    }
                    else if (this.choix === 'oui')
                    {
                        this.adjectifSelectionne = '';
                        this.adjectifQualifiant = null;
                        this.afficheValider = true;
                        this.choix = null;
                        this.etape = 1;
                    }
                    else if (this.choix === null)
                    {
                        this.choix = 'en-attente'
                    }
                }
            },

            passer()
            {
                this.$emit("adjectifAnnulé", this.adjectifSelectionne);
                this.$emit("fin-etape");
            },
        },

        watch:
        {
            adjectifQualifiant(newValue)
            {
                this.$emit('typeAdjectifValidé', this.adjectifSelectionne, newValue);
            },
        }
    }
</script>



<style scoped>
.adjectif {
    color: darkmagenta;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
</style>