<template>
    <p class="consigne">1 - Sélectionnez un adverbe.</p>

    <p>
        Adverbe sélectionné : <span class="adverbe">{{ adverbeSelectionne }}</span>
    </p>

    <div v-if="etape === 2">
        <hr class="hr-style" />
        <div class="form-control">
            <p class="consigne" :class="{ invalide: choix === 'en-attente' }">2 - Y a-t-il d'autres adverbes à
                analyser
                ?
            </p>
            <v-radio-group v-model="choix" row :class="{ invalide: choix === 'en-attente' }">
                <v-radio id="oui_choix" label="Oui" value="oui"></v-radio>
                <v-radio id="non_choix" label="Non" value="non"></v-radio>
            </v-radio-group>
        </div>
    </div>

    <div class="button-container">
        <the-button v-if="afficheValider && etape == 1" buttonType="valider" @click="validation">Valider</the-button>
        <the-button v-else-if="etape == 1" buttonType="annuler" @click="annulation">Annuler</the-button>
        <the-button buttonType="suivant_short" @click="suivant">Suivant</the-button>
        <the-button buttonType="passer" @click="passer">Passer</the-button>
    </div>
</template>

<script>
import Utility from '../../mixins/utility.js'

export default
    {
        mixins: [Utility],

        emits: ['adverbeValidé', 'adverbeAnnulé', 'fin-etape'],

        data()
        {
            return {
                adverbeSelectionne: "",
                afficheValider: true,
                etape: 1,
                choix: null
            }
        },

        methods:
        {
            sendAdverbeInformations()
            {
                const adverbeInformation =
                {
                    motAdverbe: this.adverbeSelectionne,
                };

                this.$store.dispatch('updateAdverbesList', adverbeInformation);
            },

            validation()
            {
                this.afficherValider = false;

                this.adverbeSelectionne = this.getHighlightedText();
                this.$emit("adverbeValidé", this.adverbeSelectionne);

            },

            annulation()
            {
                this.afficherValider = true;

                this.$emit("adverbeAnnulé", this.adverbeSelectionne);
                this.adverbeSelectionne = "";

            },

            suivant()
            {
                if (this.etape < 2)
                {
                    this.etape++;
                }

                else if (this.etape == 2)
                {
                    if (this.choix === 'non')
                    {
                        this.sendAdverbeInformations();
                        this.$emit("fin-etape");
                    }
                    else if (this.choix === 'oui')
                    {
                        this.sendAdverbeInformations();
                        this.conjonctionSubSelectionne = "";
                        this.afficheValider = true;
                        this.choix = null;
                        this.etape = 1;
                    }
                    else if (this.choix === null)
                    {
                        this.choix = 'en-attente'
                    }
                }
            },

            passer()
            {
                this.$emit("adverbeAnnulé", this.adverbeSelectionne);
                this.$emit("fin-etape");
            },
        }
    }
</script>

<style scoped>
.adverbe {
    text-decoration: underline;
    text-decoration-style: wavy;
}
</style>