<template>
  <p class="consigne">{{ etape }} - Changer le temps de la phrase.</p>

  <div class="container">
    <label>Hier,</label> <input type="text" v-model="phraseHier">

    <label>Aujourd'hui,</label> <input type="text" v-model="phraseAuj">

    <label>Demain,</label> <input type="text" v-model="phraseDemain">
  </div>

</template>

<script>
export default {
  props: ["etape"],

  emits: ['updatePhraseHier', 'updatePhraseAuj', 'updatePhraseDemain'],

  data()
  {
    return {
      phraseHier: "",
      phraseAuj: "",
      phraseDemain: ""
    }
  },

  watch: {
    phraseHier()
    {
      this.$emit('updatePhraseHier', this.phraseHier)
    },
    phraseAuj()
    {
      this.$emit('updatePhraseAuj', this.phraseAuj)
    },
    phraseDemain()
    {
      this.$emit('updatePhraseDemain', this.phraseDemain)
    }
  },
};
</script>



<style scoped>
label {
  float: left;
  clear: left;
  width: 60px;
  text-align: right;
  margin: 1rem;
}

input {
  float: left;
  margin: 1rem;
  width: 50%;
}

input[type="text"] {
  border: 1px solid #ccc;
  padding: 0px;
  background-color: white;
  color: black;
}
</style>
