<template>
  <p class="consigne">1 - Cliquez sur le verbe principal, puis sélectionnez le sujet de ce verbe.</p>

  <p>Sujet sélectionné : <span class="sujet">{{ sujetSelectionne }}</span></p>

  <div v-if="sujetSelectionne != ''" class="container">
    C'est&nbsp;
    <p class="capitalisation">
      <span class="sujet"> {{ sujetOuPronom(sujetSelectionne) }}</span> qui <span class="verbe">{{
        verbePrincipal
      }}</span>.
    </p>
  </div>


  <div v-if="etape === 2">
    <hr class="hr-style">
    <p class="consigne"> 2 - Choisissez le pronom qui convient pour remplacer <span class="sujet">{{
      sujetOuPronom(sujetSelectionne) }}</span>.</p>
    <div class="button-pronoms">
      <the-button buttonType="pronom" v-for="pronom in listePronoms" :key="pronom" @click="choixPronom(pronom)">{{
        pronom }}</the-button>
    </div>
  </div>

  <div v-if="etape === 2" class="container">
    <p>
      <span v-for="(mot, indice) in listeSujetRegroupe" :key="indice" :class="{
        motSelectionne: motSelectionneVerbesRegroupes(mot),
        sujet: mot === sujetSelectionne,
        verbePrincipal: mot === verbePrincipal
      }">
        {{ pronominalisationOuMot(mot) }}
      </span>
    </p>
  </div>

  <div class="button-container">
    <the-button v-if="etape === 1" buttonType="annuler" @click="annulationSujet">Annuler</the-button>
    <the-button v-if="etape === 1" buttonType="valider" @click="validationSujet">Valider</the-button>
    <the-button buttonType="suivant" @click="confirmer">Suivant</the-button>
  </div>
</template>

<script>
import Utility from '../../mixins/utility.js'

export default {
  emits: ["sujetValidé", "sujetAnnulé", 'fin-etape'],

  mixins: [Utility],

  props: ["verbePrincipal", "listeMots"],

  inject: ['motSelectionneVerbesRegroupes'],

  data()
  {
    return {
      sujetSelectionne: "",
      etape: 1,
      pronomChoisi: "_____",
      listePronoms: ["Je", "Tu", "Il", "Elle", "Nous", "Vous", "Ils", "Elles"],
      listeSujetRegroupe: []
    };
  },

  methods: {
    getHighlightedText()
    {
      const selection = window.getSelection();
      if (selection.rangeCount > 0)
      {
        const range = selection.getRangeAt(0);
        return range.toString();
      }
    },

    validationSujet()
    {
      this.annulationSujet();
      this.sujetSelectionne = this.getHighlightedText().trim();
      this.$emit("sujetValidé", this.sujetSelectionne);
    },

    annulationSujet()
    {
      this.$emit("sujetAnnulé", this.sujetSelectionne);
      this.sujetSelectionne = "";
    },

    sujetOuPronom(sujet)
    {
      /**
       * La méthode renvoie "sujet" si ce n'est pas un pronom, et sinon renvoie "moi" si sujet="je", "toi" si ...
       */

      switch (sujet)
      {
        case "Je":
        case "je":
          return "moi";
        case "tu":
          return "toi";
        case "il":
          return "lui";
        case "ils":
          return "eux";
        // Attention, il manque le cas de "on" par exemple
      }

      return sujet;
    },

    confirmer()
    {
      if (this.etape === 1)
      {
        this.listeSujetRegroupe = this.regrouperSujet(this.listeMots);
        this.etape = 2;
      }
      else if (this.etape === 2)
      {
        this.$emit('fin-etape', this.listeSujetRegroupe);
      }
    },

    longueurSujetSelectionne(chaine)
    {
      const nombreSujet = chaine.split(" ").length;

      return nombreSujet + (nombreSujet - 1);
    },

    regrouperSujet(liste)
    {
      let nouvelleListe = [...liste];

      // Séparer le sujet en mots
      let motsDuSujet = this.sujetSelectionne.split(/\s+/);

      // Trouver l'index de la première occurrence des mots dans la nouvelle liste
      let index = nouvelleListe.findIndex((mot) => motsDuSujet.includes(mot));

      // Si les mots sont trouvés dans la liste, les remplacer par le verbe complet
      if (index !== -1)
      {
        nouvelleListe.splice(
          index,
          this.longueurSujetSelectionne(this.sujetSelectionne),
          this.sujetSelectionne
        );
      }

      return nouvelleListe;
    },

    choixPronom(pronom)
    {
      this.pronomChoisi = pronom;
    },

    pronominalisationOuMot(mot)
    {
      if (mot === this.sujetSelectionne)
      {
        if (this.listeSujetRegroupe[0] != this.sujetSelectionne)
        {
          return this.pronomChoisi.toLowerCase();
        }
        return this.pronomChoisi;
      }
      return mot;
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  margin-top: 10px;
}

hr {
  color: #333;
  background-color: #333;
  height: 2px;
}


.verbePrincipal {
  font-weight: bold;
}

.sujet {
  color: red;
}

/* .verbe {
  color: blue;
  text-decoration: underline;
} */

.entoure {
  border: 1px solid #000;
  /* Couleur de la bordure (noir dans cet exemple) et épaisseur de 1 pixel */
  border-radius: 10px;
  /* Ajoute des bords arrondis avec un rayon de 10 pixels (ajustez selon vos préférences) */
  padding: 5px;
  /* Ajoute un espace à l'intérieur de la bordure pour améliorer la lisibilité (facultatif) */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button.validationSujet {
  font: inherit;
  border: 1px solid rgb(0, 90, 27);
  background-color: rgb(31, 187, 26);
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
  border-color: rgb(0, 90, 27);
  background-color: rgb(11, 88, 9);
}

.motSelectionne {
  color: blue;
  text-decoration: underline;
}

/* Ajoutez une classe à votre hr pour appliquer ces styles */
.hr-style {
  margin: 0;
  /* Supprime la marge par défaut du hr */
  border-top: 1px solid #ccc;
  /* Ajoute une bordure personnalisée */
  width: calc(100% - 5rem);
  /* Ajustez la largeur du hr en fonction de vos besoins */
}

.capitalisation::first-letter {
  text-transform: lowercase;
}
</style>
