<template>
  <p class="consigne">{{ etape }} - Le verbe est-il un verbe d'action ou un verbe attributif ?</p>
  <br>

  <div class="form-control">
    <div v-for="verbe in getVerbesList" :key="verbe">
      <p><strong>{{ verbe.mot }}</strong></p>
      <v-radio-group v-model="verbe.type" row>
        <v-radio :id="verbe.mot + 'action'" label="Verbe d'action" value="action"></v-radio>
        <v-radio :id="verbe.mot + 'attributif'" label="Verbe attributif" value="attributif"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  props: ["etape"],

  emits: ['updateTypeVerbe'],

  data()
  {
    return {
      typeVerbe: ""
    }
  },

  computed:
  {
    getVerbesList()
    {
      return this.$store.getters['getVerbesList'];
    }
  },

  watch: {
    typeVerbe()
    {
      this.$emit('updateTypeVerbe', this.typeVerbe)
    },
  }

};
</script>



<style scoped>
label {
  float: left;
  clear: left;
  width: 60px;
  text-align: right;
  margin: 1rem;
}

input {
  float: left;
  margin: 1rem;
  width: 50%;
}

input[type="text"] {
  border: 1px solid #ccc;
  padding: 0px;
  background-color: white;
  color: black;
}
</style>
