export default
    {
        initializeVerbesList(context, payload)
        {
            context.state.verbesList = payload;
        },

        updateNomsList(context, payload)
        {
            context.state.nomsList.push(payload)
        },

        updateDeterminantsList(context, payload)
        {
            context.state.determinantsList.push(payload)
        },

        updatePronomsList(context, payload)
        {
            context.state.pronomsList.push(payload);
        },

        updateConjonctionsCoordList(context, payload)
        {
            context.state.conjonctionsCoordList.push(payload);
        },

        updateConjonctionsSubList(context, payload)
        {
            context.state.conjonctionsSubList.push(payload);
        },

        updatePrepositionList(context, payload)
        {
            context.state.prepositionsList.push(payload);
        },

        updateAdverbesList(context, payload)
        {
            context.state.adverbesList.push(payload);
        },

        reset(context)
        {
            context.state.nomsList = []
            context.state.determinantsList = []
            context.state.pronomsList = []
            context.state.conjonctionsCoordList = []
            context.state.conjonctionsSubList = []
            context.state.prepositionsList = []
            context.state.adverbesList = []
        }
    }