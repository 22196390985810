<template>
  <h1>Fonctions Syntaxiques</h1>

  <v-hover v-slot:default="{ isHovering, props }">
    <v-card class="container" v-if="etape1termine === false" v-bind="props" :elevation="isHovering ? 8 : 4">
      <p class="phrasePrincipale" @mouseup="getHighlightedText">
        <span v-for="(mot, indice) in listeMotsVerbesRegroupes" :key="indice" :class="{
          // verbe: motSelectionne(mot, indice),
          ...(motSelectionneVerbesRegroupes(mot, indice) ? getVerbeClasses : {}),
          sujet: estUnSujet(mot, indice),
        }">
          {{ mot }}
        </span>
      </p>
    </v-card>
  </v-hover>

  <v-hover v-slot:default="{ isHovering, props }">
    <v-card class="container" v-if="etape1termine === true" v-bind="props" :elevation="isHovering ? 8 : 4">
      <p class="phrasePrincipale" @mouseup="getHighlightedText">
        <span v-for="(mot, indice) in listeMotsVerbesRegroupes" :key="indice" :class="{
          // verbe: motSelectionneVerbesRegroupes(mot),
          ...(motSelectionneVerbesRegroupes(mot) ? getVerbeClasses : {}),
          verbePrincipal: estUnVerbePrincipal(mot),
          sujet: estUnSujet(mot, indice),
          cod: estUnCod(mot, indice),
          coi: estUnCoi(mot, indice),
          complement: estUnComplement(mot, indice),
          estCliquable: estCliquable(mot),
        }" @click="selectionVerbePrincipal(mot)">
          {{ mot }}
        </span>
      </p>
    </v-card>
  </v-hover>

  <analyse-verbe @annuler="annulation" @annulerVerbePrincipal="annulationVerbePrincipal" @valider="validation"
    @confirmerEtape1="confirmationEtape1" @confirmerEtape2="confirmationEtape2" @finAnalyseVerbe="finAnalyseVerbe"
    v-if="partie === 'analyse-verbe'">
    <template v-slot:selection-verbe>
      <v-chip v-for="verbe in selection" :key="verbe" color="primary" class="ma-2" outlined>
        {{ verbe }}
      </v-chip>
    </template>
  </analyse-verbe>

  <analyse-fonctions-syntaxiques v-if="partie === 'analyse-fonctions-syntaxiques'" @sujetValidé="sujetValide"
    @sujetAnnulé="sujetAnnule" @codValidé="codValide" @codAnnulé="codAnnule" @coiValidé="coiValide"
    @coiAnnulé="coiAnnule" @complémentValidé="complementValide" @complémentAnnulé="complementAnnule"
    :verbe-principal="verbePrincipal" :sujet-principal="sujetSelectionne[0]" :cod="codSelectionne[0]"
    :coi="coiSelectionne[0]" :complement="complementSelectionne" :liste-mots="listeMotsVerbesRegroupes">
  </analyse-fonctions-syntaxiques>
</template>

<script>
import AnalyseVerbe from "./AnalyseVerbe/AnalyseVerbe.vue";
import AnalyseFonctionsSyntaxiques from "./AnalyseFonctionsSyntaxiques/AnalyseFonctionsSyntaxiques.vue";
import Utility from '../mixins/utility.js'

export default {
  props: ["phrase"], // On reçoit la phrase en tant que prop à partir de App.vue

  mixins: [Utility],

  provide()
  {
    return {
      verbesSelectionnes: this.selection,
      listeMots: this.listeMots,
      listeMotsRegroupes: (() => this.listeMotsVerbesRegroupes),
      getVerbePrincipal: () => this.verbePrincipal,
      motSelectionneVerbesRegroupes: this.motSelectionneVerbesRegroupes,
    };
  },

  components: {
    AnalyseVerbe,
    AnalyseFonctionsSyntaxiques,
  },

  data()
  {
    return {
      selection: [],
      texteSelectionne: "",
      // listeMotsVerbesRegroupes: [],
      partie: "analyse-verbe",
      sujetSelectionne: [],
      codSelectionne: [],
      coiSelectionne: [],
      complementSelectionne: [],
      verbePrincipal: "",
      etape1termine: false,
      etape2termine: false,
    };
  },

  computed: {
    listeMots()
    {
      // Retourne une liste avec les différents mots de la phrase, avec les apostrophes, espaces et points comment éléments de la liste mis à part. Utilise les regex.

      return this.phrase.split(/(\s+|'|’|\.|,)/).filter(Boolean); // le .filter(Boolean) permet d'enlever le caractère vide que rajoute split à la fin. Le \. est nécessaire car le simple . a une signification particulière en regex
    },

    getVerbeClasses()
    {
      return this.$store.getters['styles/getVerbeClasses']
    },

    listeMotsVerbesRegroupes()
    {
      return this.regrouperVerbes(this.listeMots);
    }
  },

  methods: {
    arrayEquals(a, b)
    {
      // Prend deux listes en entrées a et b et testent si elles ont la même longueur et contiennent les mêmes éléments aux mêmes emplacements
      // Utiliser "===" pour tester cela ne fonctionne pas en Javascript

      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    },

    getHighlightedText()
    {
      var selection = null;

      if (window.getSelection)
      {
        selection = window.getSelection();
      } else if (typeof document.selection != "undefined")
      {
        selection = document.selection;
      }

      if (selection.rangeCount > 0)
      {
        const range = selection.getRangeAt(0);
        this.texteSelectionne = range.toString();
      }
    },


    separe(groupe, mot)
    {
      // La fonction vérifie si le mot fait partie du groupe de mots et si c'est le cas, renvoie la liste (en cas de multiple occurences du mot) des listes des mots précédents et la liste des mots suivants dans le groupe

      // Vérifier si le mot est présent dans le groupe
      if (groupe.includes(mot))
      {
        let mots = groupe.split(/(\s+|'|’|\.|,)/).filter(Boolean);
        let occurrences = [];

        for (let i = 0; i < mots.length; i++)
        {
          if (mots[i] === mot)
          {
            // Liste des mots avant le mot
            let avant = mots.slice(0, i);
            // Liste des mots après le mot
            let après = mots.slice(i + 1);
            occurrences.push([avant, après]);
          }
        }

        return occurrences;
      } else
      {
        return ["Le mot n'est pas présent dans le groupe.", ""];
      }
    },

    motSelectionne(mot, indice)
    {
      // On traite le cas d'un espace entre un verbe composé
      if (mot === " ")
      {
        for (let i = 0; i < this.selection.length; i++)
        {
          if (
            this.selection[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.selection.length; i++)
      {
        if (this.selection[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    motSelectionneVerbesRegroupes(mot)
    {
      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }

      if (this.selection.includes(mot))
      {
        return true;
      }

      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    sujetValide(sujetSelectionne)
    {
      this.sujetSelectionne.push(sujetSelectionne);
    },

    sujetAnnule(sujetSelectionne)
    {
      this.sujetSelectionne = this.sujetSelectionne.filter(
        (element) => element != sujetSelectionne
      );
    },

    estUnSujet(_, indice)
    {
      for (let i = 0; i < this.sujetSelectionne.length; i++)
      {
        let sujetListe = this.chaineVersListe(this.sujetSelectionne[i]);
        if (this.motInclusDansGroupe(this.listeMots, indice, sujetListe))
        {
          return true;
        }
      }

      return false;
    },

    estUnVerbePrincipal(mot)
    {
      if (mot === this.verbePrincipal)
      {
        return true;
      }
      return false;
    },

    codValide(codSelectionne)
    {
      this.codSelectionne.push(codSelectionne);
    },

    codAnnule(codSelectionne)
    {
      this.codSelectionne = this.codSelectionne.filter(
        (element) => element != codSelectionne
      );
    },

    estUnCod(mot, indice)
    {
      // On traite le cas d'un espace entre un cod composé
      if (mot === " ")
      {
        for (let i = 0; i < this.codSelectionne.length; i++)
        {
          if (
            this.codSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.codSelectionne.length; i++)
      {
        if (this.codSelectionne[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    coiValide(coiSelectionne)
    {
      this.coiSelectionne.push(coiSelectionne);
    },

    coiAnnule(coiSelectionne)
    {
      this.coiSelectionne = this.coiSelectionne.filter(
        (element) => element != coiSelectionne
      );
    },

    estUnCoi(mot, indice)
    {
      // On traite le cas d'un espace entre un cod composé
      if (mot === " ")
      {
        for (let i = 0; i < this.coiSelectionne.length; i++)
        {
          if (
            this.coiSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.coiSelectionne.length; i++)
      {
        if (this.coiSelectionne[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    complementValide(texteSelectionne)
    {
      this.complementSelectionne.push(texteSelectionne);
    },

    complementAnnule()
    {
      this.complementSelectionne = [];
    },

    estUnComplement(_, indice)
    {
      for (let i = 0; i < this.complementSelectionne.length; i++)
      {
        let complementListe = this.chaineVersListe(this.complementSelectionne[i]);

        if (this.motInclusDansGroupe(this.listeMotsVerbesRegroupes, indice, complementListe))
        {
          return true;
        }
      }

      return false;
    },

    annulation()
    {
      this.selection = [];
    },

    annulationVerbePrincipal()
    {
      this.verbePrincipal = "";
    },

    validation()
    {
      this.selection.push(this.texteSelectionne.trim());
    },

    confirmationEtape1()
    {
      const listeVerbes = this.selection.map(verbe => (
        {
          mot: verbe,
          type: ""
        }
      ));

      this.$store.dispatch('initializeVerbesList', listeVerbes);

      this.etape1termine = true;
    },

    confirmationEtape2()
    {
      this.etape2termine = true;
    },

    longueurVerbeSelectionne(chaine)
    {
      const nombreVerbes = chaine.split(" ").length;

      return nombreVerbes + (nombreVerbes - 1);
    },

    regrouperVerbes(liste)
    {
      let nouvelleListe = [...liste];
      for (const verbe of this.selection)
      {
        // Séparer le verbe en mots
        let motsDuVerbe = this.chaineVersListe(verbe);
        let motsDuVerbeSansEspace = verbe.trim().split(/\s+/);

        // Trouver l'index de la première occurrence des mots dans la nouvelle liste
        let index = nouvelleListe.findIndex((mot) => motsDuVerbeSansEspace.includes(mot));

        // Si les mots sont trouvés dans la liste, les remplacer par le verbe complet
        if (index !== -1) // on teste si un mot du verbe a bien été trouvé
        {
          nouvelleListe.splice(
            index, // on commence à remplacer à partir de l'indice index
            motsDuVerbe.length, // on remplace ce nombe d'éléments dans la liste
            verbe
          );
        }
      }

      return nouvelleListe;
    },

    finAnalyseVerbe()
    {
      this.partie = "analyse-fonctions-syntaxiques";
    },

    estCliquable(mot)
    {
      if (this.selection.includes(mot) && this.partie === 'analyse-fonctions-syntaxiques' && this.sujetSelectionne.length == 0)
      {
        return true;
      }

      return false;
    },

    selectionVerbePrincipal(mot)
    {
      // Les mots ne sont pas interragibles si on est pas à l'étape 2 ou que le mot n'est pas un verbe
      // if (
      //   !this.etape1termine ||
      //   this.etape2termine ||
      //   !this.selection.includes(mot)
      // )
      // {
      //   return null;
      // }

      if (this.selection.includes(mot) && this.partie === 'analyse-fonctions-syntaxiques' && this.sujetSelectionne.length == 0)
      {

        this.verbePrincipal = mot;
      }

      return null
    },
  },
};
</script>

<style scoped>
h1 {
  margin-left: 1rem;
}

p {
  margin-left: 1rem;
}

.verbePrincipal {
  font-weight: bold;
}

.sujet {
  color: red;
}

.cod {
  color: green;
}

.coi {
  color: rgb(151, 108, 13);
}

.complement {
  color: blueviolet;
}

.container {
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.phrasePrincipale {
  font-size: 200%;
}

.estCliquable {
  cursor: pointer;
}
</style>
