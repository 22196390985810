<template>
    <v-hover v-slot:default="{ isHovering, props }">
        <v-form @submit.prevent="submitForm" v-bind="props"
            :class="{ 'elevation-4': isHovering, 'elevation-2': !isHovering }">

            <div class="settings">
                <v-btn icon @click="triggerBoite('aide')" class="help-button" variant="elevated">
                    <v-icon icon="fa:fas fa-question" />
                </v-btn>
                <v-btn icon @click="triggerBoite('parametres')" class="help-button ml-2" dark variant="elevated">
                    <v-icon icon="fa:fas fa-cog" />
                </v-btn>
            </div>


            <h2>Analyse grammaticale</h2>

            <v-container class="form-control mt-15 mb-1">
                <v-select id="corpus" v-model="corpus" :items="corpusItems" item-value="key" item-text="Titre"
                    :class="{ invalide: choixValiditeCorpus === 'invalide' }" variant="outlined"><template
                        v-slot:label><strong>Choix du corpus</strong></template></v-select>

                <v-text-field v-if="corpus === 'phrasePersonnalisee'" label="Entrez une phrase" v-model="phraseChoisie"
                    :class="{ invalide: choixValiditeCorpus === 'invalide' }" density="compact" />
                <v-alert v-if="corpus === 'phrasePersonnalisee' && choixValiditeCorpus === 'invalide'" type="error"
                    border="left" class="mt-2">
                    Veuillez entrer une phrase.
                </v-alert>


                <div class="form-control">
                    <v-radio-group v-model="analyse" row :class="{ invalide: choixValiditeAnalyse === 'invalide' }">
                        <template v-slot:label>
                            <strong>Choix de l'analyse</strong>
                        </template>
                        <v-radio id="fonctions-syntaxiques" label="Fonctions syntaxiques"
                            value="fonctions-syntaxiques"></v-radio>
                        <v-radio id="classes-grammaticales" label="Classes grammaticales"
                            value="classes-grammaticales"></v-radio>
                    </v-radio-group>
                </div>
            </v-container>

            <div>
                <v-btn variant="outlined" type="submit" color="#ce0067">
                    Valider
                    <template v-slot:append>
                        <v-icon icon="fa:fas fa-check-circle" />
                    </template>
                </v-btn>
            </div>
        </v-form>
    </v-hover>

    <p v-if="choixValiditeCorpus === 'valide' && choixValiditeAnalyse === 'valide'">Merci pour vos choix !</p>
    <p v-else>Choisissez les options.</p>

    <teleport to="body">
        <the-help :open="boite_aide" @valider="aideValider"></the-help>
        <the-parameters :open="boite_parametres" @valider="parametresValider"></the-parameters>
    </teleport>
</template>


<script>
import TheParameters from './Boites/TheParameters.vue';
import TheHelp from './Boites/TheHelp.vue';

export default {
    emits: ['validationChoix'],

    props: ['corpusData'],

    components: {
        TheParameters,
        TheHelp
    },

    data()
    {
        return {
            corpus: null, // pour le 1er choix
            analyse: null, // pour le 2nd choix
            choixValiditeCorpus: 'en_attente', // est-ce que les choix ont bien été effectués?
            choixValiditeAnalyse: 'en_attente',
            phraseChoisie: '',
            boite_parametres: false,
            boite_aide: false
        }
    },

    methods: {
        submitForm()
        {
            // On vérifique que les paramètres ont été choisis
            this.choixValiditeCorpus = (this.corpus === null) || (this.corpus === 'phrasePersonnalisee' && this.phraseChoisie === '') ? 'invalide' : 'valide';
            this.choixValiditeAnalyse = this.analyse === null ? 'invalide' : 'valide';

            // On transmet les informations à App.vue si les informations ont été fournies
            if (this.choixValiditeCorpus === 'valide' && this.choixValiditeAnalyse === 'valide')
            {
                this.$emit('validationChoix', this.corpus, this.analyse, this.phraseChoisie);
            }
        },

        triggerBoite(type)
        {
            if (type === 'aide') { this.boite_aide = true }
            else if (type === 'parametres') { this.boite_parametres = true }
        },

        aideValider()
        {
            this.boite_aide = false;
        },

        parametresValider()
        {
            this.boite_parametres = false;
        }
    },

    computed:
    {
        corpusItems()
        {
            return Object.keys(this.corpusData).map(key => ({
                key: key,
                title: this.corpusData[key].Titre
            }));
        }
    }
};
</script>


<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

form {
    margin: 2rem auto;
    max-width: 40rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 2rem;
    background-color: #ffffff;
}

h2 {
    margin-left: 0rem;
}

.form-control {
    margin: 2rem 0;
}

.invalide {
    color: red;
}

label {
    font-weight: bold;
}

input,
select {
    display: block;
    width: 100%;
    font: inherit;
    margin-top: 0.5rem;
}

input[type='radio'] {
    display: inline-block;
    width: auto;
    margin-right: 1rem;
}

input[type='radio']+label {
    font-weight: normal;
}

/* button {
    font: inherit;
    border: 1px solid #ce0067;
    background-color: #ce0067;
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
} */

/* button:hover,
button:active {
    border-color: #77003c;
    background-color: #77003c;
} */

p {
    text-align: center;
}

.settings {
    float: right;
}

.settings-button {
    position: relative;
    padding: 5px 10px;
    background-color: #5f6366;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1rem;
    /* Espacement entre les boutons si nécessaire */
}

.help-button {
    background-color: #ce0067;
    color: white;
}
</style>