<template>
    <v-dialog v-model="dialog" @click:outside="closeDialog" max-width="40%">
        <v-card>
            <v-card-title>Paramètres</v-card-title>
            <v-card-subtitle>En cours de construction.</v-card-subtitle>
            <v-card-text>
                <v-row align="center">
                    <!-- Titre Verbe -->
                    <v-col cols="2">
                        <strong>Verbe</strong>
                    </v-col>

                    <!-- Première colonne : choix de la couleur -->
                    <v-col cols="4">
                        <v-select v-model="verbeColor" :items="getColorOptions" label="Couleur"></v-select>
                    </v-col>

                    <!-- Deuxième colonne : choix entre souligné ou entouré -->
                    <v-col cols="4">
                        <v-select v-model="verbeStyle" :items="getStyleOptions" label="Style"></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="valider" color="primary" dark>Valider</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    emits: ['valider'],

    props: ['open'],

    data()
    {
        return {
            dialog: this.open,
            verbeColor: "",
            verbeStyle: ""
        };
    },

    watch: {
        open(val)
        {
            // Mettre à jour le dialog lorsque open change
            this.dialog = val;
        }
    },

    methods: {
        closeDialog()
        {
            this.$emit('valider');
            this.dialog = false;
        },

        valider()
        {
            this.$emit('valider');
            this.dialog = false;

            const verbeClasses = { [this.verbeColor]: true, [this.verbeStyle]: true };

            this.$store.dispatch('styles/updateVerbeClasses', verbeClasses);
        }
    },

    computed:
    {
        getColorOptions()
        {
            return this.$store.getters['styles/getColorOptions']
        },

        getStyleOptions()
        {
            return this.$store.getters['styles/getStyleOptions']
        }
    }
};
</script>

<style scoped>
/* Styles personnalisés pour le v-card et le v-dialog si nécessaire */
.v-card-title {
    background-color: #640032;
    color: white;
    padding: 1rem;
    font-size: 1.5rem;
}

.v-card-subtitle {
    padding: 1rem;
}
</style>