import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

export default
    {
        namespaced: true,

        state()
        {
            return {
                colorOptions: ['rouge', 'vert', 'bleu', 'noir'],
                styleOptions: ['aucun', 'souligné', 'entouré', 'double_souligné', 'vague', 'cercle_flèché'],
                verbeClasses: { 'entouré': true, 'noir': true }
            }
        },

        mutations,
        actions,
        getters
    }