import { createApp } from 'vue'
import App from './App.vue'
import store from './store/index.js'

// Vuetify
import 'vuetify/styles'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vuetify/dist/vuetify.min.css';
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { fa } from "vuetify/iconsets/fa";

const vuetify = createVuetify({
    components,
    directives,
    aliases,
    icons: {
        defaultSet: 'mdi',
        sets: {
            mdi,
            fa,
        },
    },
});

const app = createApp(App);

import TheButton from './components/UI/TheButton.vue';
app.component('TheButton', TheButton);


app.use(store);
app.use(vuetify);
app.mount('#app')
