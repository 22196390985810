<template>
  <p class="consigne">1 - Sélectionnez un nom commun.</p>

  <p>
    Nom sélectionné : <span class="nom">{{ nomSelectionne }}</span>
  </p>

  <div v-if="etape >= 2">
    <hr class="hr-style" />
    <p class="consigne">
      2 - Est-il lié à un déterminant ? Si oui, sélectionner le déterminant lié au nom "<span class="nom">{{
        nomSelectionne }}</span>".
    </p>
    Déterminant sélectionné :
    <span class="determinant">{{ determinantSelectionne }}</span>
  </div>

  <div v-if="etape === 3">
    <hr class="hr-style" />
    <div class="form-control">
      <p class="consigne" :class="{ invalide: choix === 'en-attente' }">3 - Y a-t-il d'autres noms à analyser ?</p>
      <v-radio-group v-model="choix" row :class="{ invalide: choix === 'en-attente' }">
        <v-radio id="oui" label="Oui" value="oui"></v-radio>
        <v-radio id="non" label="Non" value="non"></v-radio>
      </v-radio-group>
    </div>
  </div>

  <div class="button-container">
    <the-button v-if="etape <= 2" buttonType="annuler" @click="annulation">Annuler</the-button>
    <the-button v-if="etape <= 2" buttonType="valider" @click="validation">Valider</the-button>
    <the-button buttonType="suivant_short" @click="confirmer">Suivant</the-button>
    <the-button buttonType="passer" @click="passer">Passer</the-button>
  </div>
</template>

<script>
import Utility from '../../mixins/utility.js'

export default {
  emits: ["nomValidé", "nomAnnulé", "determinantValidé", "determinantAnnulé"],

  mixins: [Utility],

  data()
  {
    return {
      nomSelectionne: "",
      determinantSelectionne: "",
      etape: 1,
      choix: null
    };
  },

  methods: {
    validation()
    {
      if (this.etape === 1)
      {
        this.nomSelectionne = this.getHighlightedText().trim();
        this.$emit("nomValidé", this.nomSelectionne);
      }
      else if (this.etape === 2)
      {
        this.determinantSelectionne = this.getHighlightedText();
        this.determinantSelectionne = this.enleverEspaces(this.determinantSelectionne);
        this.$emit("determinantValidé", this.determinantSelectionne);
      }
    },

    annulation()
    {
      if (this.etape === 1)
      {
        this.$emit("nomAnnulé", this.nomSelectionne);
        this.nomSelectionne = "";
      }
      else if (this.etape === 2)
      {
        this.$emit("determinantAnnulé", this.determinantSelectionne);
        this.determinantSelectionne = "";
      }
    },

    confirmer()
    {
      if (this.etape === 1)
      {
        this.etape = 2;
      } else if (this.etape === 2)
      {
        this.etape = 3;
      }
      else if (this.etape === 3)
      {
        if (this.choix === 'non')
        {
          this.sendNomsInformations();
          this.sendDeterminantInformations();
          this.$emit("fin-etape");
        }
        else if (this.choix === 'oui')
        {
          this.sendNomsInformations();
          this.sendDeterminantInformations(),
            this.nomSelectionne = '';
          this.determinantSelectionne = '';
          this.choix = null;
          this.etape = 1;
        }
        else if (this.choix === null)
        {
          this.choix = 'en-attente'
        }
      }
    },
    passer()
    {
      this.$emit("nomAnnulé", this.nomSelectionne);
      this.$emit("determinantAnnulé", this.determinantSelectionne);
      this.$emit("fin-etape");
    },

    sendNomsInformations()
    {
      const Nom =
      {
        mot: this.nomSelectionne,
      };

      this.$store.dispatch('updateNomsList', Nom);
    },

    sendDeterminantInformations()
    {
      const Determinant =
      {
        mot: this.determinantSelectionne,
        nomAssocie: this.nomSelectionne,
        remplacant: "",
        nature: ""
      };

      this.$store.dispatch('updateDeterminantsList', Determinant);
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  margin-top: 10px;
}

hr {
  color: #333;
  background-color: #333;
  height: 2px;
}

.verbePrincipal {
  font-weight: bold;
}

.sujet {
  color: red;
}

/* .verbe {
  color: blue;
  text-decoration: underline;
} */

.nom {
  color: rgb(155, 132, 5);
}

.determinant {
  color: darksalmon;
}

.entoure {
  border: 1px solid #000;
  /* Couleur de la bordure (noir dans cet exemple) et épaisseur de 1 pixel */
  border-radius: 10px;
  /* Ajoute des bords arrondis avec un rayon de 10 pixels (ajustez selon vos préférences) */
  padding: 5px;
  /* Ajoute un espace à l'intérieur de la bordure pour améliorer la lisibilité (facultatif) */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button.confirmer {
  font: inherit;
  border: 1px solid rgb(0, 0, 0);
  background-color: #770a41;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

button.confirmer:hover,
button.confirmer:active {
  border-color: rgb(0, 90, 27);
  background-color: #500029;
}

button.validation {
  font: inherit;
  border: 1px solid rgb(0, 90, 27);
  background-color: rgb(31, 187, 26);
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
  border-color: rgb(0, 90, 27);
  background-color: rgb(11, 88, 9);
}

button.pronom {
  box-shadow: 0px 1px 0px 0px #1c1b18;
  background: linear-gradient(to bottom, #eae0c2 5%, #ccc2a6 100%);
  background-color: #eae0c2;
  border-radius: 15px;
  border: 2px solid #333029;
  display: inline-block;
  cursor: pointer;
  color: #505739;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 16px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
  margin-right: 20px;
}

button.pronom:hover {
  background: linear-gradient(to bottom, #ccc2a6 5%, #eae0c2 100%);
  background-color: #ccc2a6;
}

button.pronom:active {
  position: relative;
  top: 1px;
}

.motSelectionne {
  color: blue;
  text-decoration: underline;
}
</style>
