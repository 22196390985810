<template>
    <p class="consigne">1 - Sélectionnez une conjonction de coordination.</p>

    <p>
        Conjonction de coordination sélectionnée : <span v-if="conjonctionCoordSelectionne" class="conjonction-coord">{{
            conjonctionCoordSelectionne }}</span>
    </p>

    <div v-if="etape >= 2">
        <hr class="hr-style mt-2 mb-5" />
        <p class="consigne">2 - Cette conjonction de coordination relie 2 éléments de même nature. De quelle nature
            grammaticale s'agit-t-il ?</p>
        <v-col>
            <v-text-field :label="conjonctionCoordSelectionne" v-model="nature" density="compact" variant="solo"
                :loading="!nature" />
        </v-col>
    </div>

    <div v-if="etape === 3">
        <hr class="hr-style" />
        <div class="form-control">
            <p class="consigne" :class="{ invalide: choix === 'en-attente' }">3 - Y a-t-il d'autres conjonctions de
                coordination à analyser
                ?
            </p>
            <v-radio-group v-model="choix" row :class="{ invalide: choix === 'en-attente' }">
                <v-radio id="oui_choix" label="Oui" value="oui"></v-radio>
                <v-radio id="non_choix" label="Non" value="non"></v-radio>
            </v-radio-group>
        </div>
    </div>


    <div class="button-container">
        <the-button v-if="afficheValider && etape == 1" buttonType="valider" @click="validation">Valider</the-button>
        <the-button v-else-if="etape == 1" buttonType="annuler" @click="annulation">Annuler</the-button>
        <the-button buttonType="suivant_short" @click="suivant">Suivant</the-button>
        <the-button buttonType="passer" @click="passer">Passer</the-button>
    </div>
</template>

<script>
import Utility from '../../mixins/utility.js'

export default
    {
        mixins: [Utility],

        emits: ['conjonctionCoordValidé', 'conjonctionCoordAnnulé', 'fin-etape'],

        data()
        {
            return {
                conjonctionCoordSelectionne: "",
                nature: "",
                afficheValider: true,
                etape: 1,
                choix: null
            }
        },

        methods:
        {
            sendConjonctionCoordInformations()
            {
                const ConjonctionCoordInformation =
                {
                    motConjonctionCoord: this.conjonctionCoordSelectionne,
                    nature: this.nature,
                };

                this.$store.dispatch('updateConjonctionsCoordList', ConjonctionCoordInformation);
            },

            validation()
            {
                this.afficheValider = false;

                if (this.etape === 1)
                {
                    this.conjonctionCoordSelectionne = this.getHighlightedText();
                    this.$emit("conjonctionCoordValidé", this.conjonctionCoordSelectionne);
                }
            },

            annulation()
            {
                this.afficheValider = true;

                if (this.etape === 1)
                {
                    this.$emit("conjonctionCoordAnnulé", this.conjonctionCoordSelectionne);
                    this.conjonctionCoordSelectionne = "";
                }
            },

            suivant()
            {
                if (this.etape < 3)
                {
                    this.etape++;
                }

                else if (this.etape == 3)
                {
                    if (this.choix === 'non')
                    {
                        this.sendConjonctionCoordInformations();
                        this.$emit("fin-etape");
                    }
                    else if (this.choix === 'oui')
                    {
                        this.sendConjonctionCoordInformations();
                        this.conjonctionCoordSelectionne = "";
                        this.nature = "";
                        this.afficheValider = true;
                        this.choix = null;
                        this.etape = 1;
                    }
                    else if (this.choix === null)
                    {
                        this.choix = 'en-attente'
                    }
                }
            }
            ,

            passer()
            {
                this.$emit("conjonctionCoordAnnulé", this.conjonctionCoordSelectionne);
                this.$emit("fin-etape");
            },
        }
    }
</script>


<style scoped>
.conjonction-coord {
    padding: 5px;
    margin: 0 40px;
    border-radius: 20px;
    border: 2px solid green;
    display: inline-block;
    position: relative;
}

.conjonction-coord::before {
    content: "";
    position: absolute;
    /* Utilise une position absolue */
    left: -30px;
    /* Ajuste pour positionner les flèches à gauche */
    right: -30px;
    /* Ajuste pour positionner les flèches à droite */
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    background:
        /* arrow */
        linear-gradient(to bottom right, green 49%, transparent 52%) bottom right/15px 50%,
        linear-gradient(to top right, green 49%, transparent 52%) top right/15px 50%,
        /* circle */
        /* radial-gradient(green 56%, transparent 60%) -2px 50%/20px 20px, */
        /* line */
        linear-gradient(green, green) right center/30px 2px,
        linear-gradient(green, green) left center /30px 2px,
        /* arrow */
        linear-gradient(to bottom left, green 49%, transparent 52%) bottom left/15px 50%,
        linear-gradient(to top left, green 49%, transparent 52%) top left/15px 50%;
    background-repeat: no-repeat;
    z-index: 1;
    /* Assure la visibilité des flèches */
}



.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.v-text-field {
    width: 40%
}
</style>