export default
    {
        getVerbesList(state)
        {
            return state.verbesList;
        },

        getNomsList(state)
        {
            return state.nomsList;
        },

        getDeterminantsList(state)
        {
            return state.determinantsList;
        },

        getPronomsList(state)
        {
            return state.pronomsList;
        },

        getConjonctionsCoordList(state)
        {
            return state.conjonctionsCoordList;
        },

        getConjonctionsSubList(state)
        {
            return state.conjonctionsSubList;
        },
        getPrepositionsList(state)
        {
            return state.prepositionsList;
        },
        getAdverbesList(state)
        {
            return state.adverbesList;
        }
    }