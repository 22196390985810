<template>
    <v-btn variant="outlined" elevation="4" :class="[buttonClass]">
        <slot></slot>
        <template v-slot:append>
            <v-icon v-if="buttonType === 'valider'" icon="fa:fas fa-check-circle" />
            <v-icon v-if="buttonType === 'annuler'" icon="fa:fas fa-circle-xmark" />
            <v-icon v-if="buttonType === 'suivant'" icon="fa:fas fa-arrow-right" />
            <v-icon v-if="buttonType === 'suivant_short'" icon="fa:fas fa-arrow-right" />
            <v-icon v-if="buttonType === 'precedent'" icon="fa:fas fa-fast-backward" />
            <v-icon v-if="buttonType === 'passer'" icon="fa:fas fa-fast-forward" />
            <v-icon v-if="buttonType === 'telecharger'" icon="fa:fas fa-save" />
        </template>
    </v-btn>
</template>

<script>
export default
    {
        props: ["buttonType"],

        computed:
        {
            buttonClass()
            {
                return { // on retourne un objet ou chaque clé est le nom d'une classe CSS et chaque valeur une expression booléenne
                    'valider': this.buttonType === 'valider',
                    'annuler': this.buttonType === 'annuler',
                    'suivant': this.buttonType === 'suivant',
                    'suivant_short': this.buttonType === 'suivant_short',
                    'precedent': this.buttonType === 'precedent',
                    'passer': this.buttonType === 'passer',
                    'telecharger': this.buttonType === 'telecharger',
                    'ml-2': this.buttonType == 'valider' || this.buttonType == 'annuler' || this.buttonType == 'telecharger',
                    'mt-2': this.buttonType == 'telecharger'
                };
            }
        }
    }
</script>


<style scoped>
.valider {
    font: inherit;
    /* border: 1px solid rgb(0, 90, 27); */
    background-color: green;
    border: green;
    color: white;
    /* cursor: pointer; */
    /* padding: 0.75rem 2rem;
    border-radius: 30px;
    padding: 10px;
    margin-right: 10px; */
    margin-top: 10px;
}

.annuler {
    font: inherit;
    /* border: 1px solid #000000; */
    background-color: #727071;
    border: #727071;
    color: white;
    cursor: pointer;
    /* padding: 0.75rem 2rem;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px; */
    margin-top: 10px;
}

.suivant {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: gold;
    cursor: pointer;
    padding: 0.5rem 1rem;
    /* border-radius: 12px; */
    position: absolute;
    /* bottom: 0; */
    top: 0;
    right: 0;
    height: 100% !important;
    width: 10%;
    /* box-sizing: border-box; */
}

.suivant_short {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: gold;
    cursor: pointer;
    padding: 0.5rem 1rem;
    /* border-radius: 12px; */
    position: absolute;
    /* bottom: 0; */
    top: 0;
    right: 0;
    height: 80% !important;
    width: 10%;
    /* box-sizing: border-box; */
}

.passer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #acacac;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    /* border-radius: 12px; */
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20% !important;
    /* box-sizing: border-box; */
}

.suivant_short,
.passer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
    width: 10%;
    /* Force les boutons à occuper toute la largeur du conteneur */
    padding: 0.5rem 1rem;
}

.precedent {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #acacac;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    /* border-radius: 12px; */
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20% !important;
    /* box-sizing: border-box; */
    width: 10%;
}

.telecharger {
    position: absolute;
}
</style>