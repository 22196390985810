<template>
    <p class="consigne">1 - Sélectionnez les compléments circonstanciels.</p>

    <div>
        <p>Votre sélection :</p>
        <v-chip v-for="complement in selection" :key="complement" color="primary" class="ma-2" outlined>
            {{ complement }}
        </v-chip>
    </div>

    <div class="button-container">
        <the-button buttonType="annuler" @click="annulation">Annuler</the-button>
        <the-button buttonType="valider" @click="validation">Valider</the-button>
        <the-button buttonType="suivant" @click="confirmation">Suivant</the-button>
    </div>
</template>

<script>
import Utility from '../../mixins/utility.js';

export default {
    emits: ["complémentValidé", "complémentAnnulé", 'fin-etape'],

    mixins: [Utility],

    data()
    {
        return {
            selection: [],
            texteSelectionne: "",
        };
    },

    methods: {
        annulation()
        {
            this.$emit("complémentAnnulé");
            this.texteSelectionne = '';
            this.selection = [];
        },

        validation()
        {
            this.texteSelectionne = this.getHighlightedText();

            this.selection.push(this.texteSelectionne);
            this.$emit("complémentValidé", this.texteSelectionne);
        },

        getHighlightedText()
        {
            const selection = window.getSelection();
            if (selection.rangeCount > 0)
            {
                const range = selection.getRangeAt(0);
                return range.toString();
            }
        },

        confirmation()
        {
            this.$emit('fin-etape')
        }
    }
}
</script>


<style scoped>
.button-container {
    /* pour que les deux buttons soient côte a côte */
    display: flex;
    justify-content: center;
}

button.confirmer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

button.confirmer:hover,
button.confirmer:active {
    border-color: rgb(0, 90, 27);
    background-color: #500029;
}


button.validationVerbe {
    font: inherit;
    border: 1px solid rgb(0, 90, 27);
    background-color: rgb(31, 187, 26);
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
    border-color: rgb(0, 90, 27);
    background-color: rgb(11, 88, 9);
}
</style>