import { createStore } from 'vuex';

import state from './state.js';
import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

import stylesModule from './modules/styles/index.js';

const store = createStore(
    {
        state,
        mutations,
        actions,
        getters,

        modules:
        {
            styles: stylesModule
        }
    }
)

export default store;