<template>
  <p class="consigne">1 - Remplacez le déterminant par un autre.</p>

  <div class="container">
    <span v-for="mot in listeMotsRegroupes()" :key="mot">
      {{ motAffiche(mot) }}
    </span>
  </div>

  <div v-if="etape === 1">
    <div v-for="determinant in getDeterminantsList.filter(determinant => determinant.mot != '')" :key="determinant"
      class="listeDeterminants">
      <v-col>
        <v-text-field :label="determinant.mot" v-model="determinant.remplacant" density="compact" variant="solo"
          :loading="!determinant.remplacant" />
      </v-col>
    </div>
  </div>

  <div v-if="etape == 2">
    <p class="consigne">2 - Identifier précisément le déterminant (le nommer en complétant).</p>
    <div v-for="determinant in getDeterminantsList.filter(determinant => determinant.mot != '')" :key="determinant"
      class="listeDeterminants">
      <v-col>
        <v-text-field :label="determinant.mot" v-model="determinant.nature" density="compact" variant="solo"
          :loading="!determinant.nature" />
      </v-col>
    </div>
  </div>

  <div class="button-container">
    <the-button buttonType="suivant" @click="confirmer">Suivant</the-button>
  </div>
</template>

<script>
import "../../mixins/utility.js"
import Utility from '../../mixins/utility.js'

export default {
  mixins: [Utility],

  emits: ['updateDeterminantsValues'],

  inject: ["listeMotsRegroupes"],

  data()
  {
    return {
      determinantsValues: {},
      etape: 1
    }
  },

  computed:
  {
    getDeterminantsList()
    {
      return this.$store.getters.getDeterminantsList;
    },

    getDeterminantsMotsList()
    {
      return this.getDeterminantsList.map(determinant => determinant.mot)
    }
  },

  watch: {
    determinantsValues: {
      handler(newValue)
      {
        this.$emit('updateDeterminantsValues', newValue)
      },
      deep: true // à utiliser quand on veut surveiller un objet, sinon on ne passe jamais dans le watch
    }
  },

  methods: {
    motAffiche(mot)
    {
      if (mot === " ")
      {
        return "\u00a0";
      }
      else if (this.getDeterminantsList.some(d => d.mot === mot))
      {
        // Trouver l'objet dans la liste où "mot" correspond
        const determinant = this.getDeterminantsList.find(d => d.mot === mot);

        return determinant.remplacant || "____"; // quand l'utilisateur n'a pas encore rempli le champ, la valeur de la clef est 'null'
      } else
      {
        return mot;
      }
    },

    confirmer()
    {
      if (this.etape == 1)
      {
        this.etape++;
      }

      else if (this.etape == 2)
      {

        this.$emit("fin-etape");
      }
    },

    sendDeterminantInformations()
    {
      const Determinant =
      {
        mot: "",
        remplacant: "",
        nature: ""
      };

      this.$store.dispatch('updateDeterminantsList', Determinant);
    }
  }
}
</script>



<style scoped>
.v-text-field {
  width: 10%
}

.v-text-field :deep(label) {
  color: darksalmon;
  /* background-color: orange; */
  opacity: 100;
}

.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

/* .verbe {
  color: blue;
  margin-right: 1rem;
} */

.determinant {
  color: darksalmon;
}

input {
  position: absolute;
  margin-left: 15rem;
}

.listeDeterminants {
  display: flex;
  padding: 1px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button.validation {
  font: inherit;
  border: 1px solid rgb(0, 90, 27);
  background-color: rgb(31, 187, 26);
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
  border-color: rgb(0, 90, 27);
  background-color: rgb(11, 88, 9);
}

button.annuler {
  font: inherit;
  border: 1px solid #000000;
  background-color: #727071;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.annuler:hover,
button.annuler:active {
  border-color: #000000;
  background-color: #353535;
}
</style>