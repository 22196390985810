<template>
    <p class="consigne">1 - Sélectionnez une conjonction de subordination.</p>

    <p>
        Conjonction de subordination sélectionnée : <span v-if="conjonctionSubSelectionne" class="conjonctionSub">{{
            conjonctionSubSelectionne }}</span>
    </p>

    <div v-if="etape === 2">
        <hr class="hr-style" />
        <div class="form-control">
            <p class="consigne" :class="{ invalide: choix === 'en-attente' }">2 - Y a-t-il d'autres conjonctions de
                subordination à analyser
                ?
            </p>
            <v-radio-group v-model="choix" row :class="{ invalide: choix === 'en-attente' }">
                <v-radio id="oui_choix" label="Oui" value="oui"></v-radio>
                <v-radio id="non_choix" label="Non" value="non"></v-radio>
            </v-radio-group>
        </div>
    </div>

    <div class="button-container">
        <the-button v-if="afficheValider && etape == 1" buttonType="valider" @click="validation">Valider</the-button>
        <the-button v-else-if="etape == 1" buttonType="annuler" @click="annulation">Annuler</the-button>
        <the-button buttonType="suivant_short" @click="suivant">Suivant</the-button>
        <the-button buttonType="passer" @click="passer">Passer</the-button>
    </div>
</template>

<script>
import Utility from '../../mixins/utility.js'

export default
    {
        mixins: [Utility],

        emits: ['conjonctionSubValidé', 'conjonctionSubAnnulé', 'fin-etape'],

        data()
        {
            return {
                conjonctionSubSelectionne: "",
                afficheValider: true,
                etape: 1,
                choix: null
            }
        },

        methods:
        {
            sendConjonctionSubInformations()
            {
                const ConjonctionSubInformation =
                {
                    motConjonctionSub: this.conjonctionSubSelectionne,
                    nature: this.nature,
                };

                this.$store.dispatch('updateConjonctionsSubList', ConjonctionSubInformation);
            },

            validation()
            {
                this.afficherValider = false;

                if (this.etape == 1)
                {
                    this.conjonctionSubSelectionne = this.getHighlightedText();
                    this.$emit("conjonctionSubValidé", this.conjonctionSubSelectionne);
                }

            },

            annulation()
            {
                this.afficherValider = true;

                if (this.etape == 1)
                {
                    this.$emit("conjonctionSubAnnulé", this.conjonctionSubSelectionne);
                    this.conjonctionSubSelectionne = "";
                }

            },

            suivant()
            {
                if (this.etape < 2)
                {
                    this.etape++;
                }

                else if (this.etape == 2)
                {
                    if (this.choix === 'non')
                    {
                        this.sendConjonctionSubInformations();
                        this.$emit("fin-etape");
                    }
                    else if (this.choix === 'oui')
                    {
                        this.sendConjonctionSubInformations();
                        this.conjonctionSubSelectionne = "";
                        this.afficheValider = true;
                        this.choix = null;
                        this.etape = 1;
                    }
                    else if (this.choix === null)
                    {
                        this.choix = 'en-attente'
                    }
                }
            },

            passer()
            {
                this.$emit("conjonctionSubAnnulé", this.conjonctionSubSelectionne);
                this.$emit("fin-etape");
            },
        }
    }
</script>






<style>
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.conjonctionSub {
    color: green;
}
</style>