<template>
  <h2 class="mb-5"> <v-icon icon="fa:fas fa-book" /> Récapitulatif</h2>

  <div class="mt-5"> <!-- Nom -->
    <h3>Nom</h3>
    <div v-if="getNomSelectionne().length != 0">

      Vous avez sélectionné les noms suivants :

      <ul>
        <li v-for="nom in getNomSelectionne()" :key="nom">
          <span class="nom"> {{ nom }} </span>
        </li>
      </ul>
    </div>
    <div v-else>
      Vous n'avez pas sélectionné de nom.
    </div>
  </div>

  <div class="mt-2"> <!-- Déterminant -->
    <h3>Déterminant</h3>

    <ul>
      <li v-for="(determinant, indice) in getDeterminantsList" :key="indice">
        <div v-if="determinant.mot != ''">
          Le déterminant associé au nom «<span class="nom">{{ determinant.nomAssocie }}</span>» est «<span
            class="determinant">{{ determinant.mot }}</span>» (<strong>{{ determinant.nature }}</strong>).
          Il peut être remplacé par «<span class="determinant">{{ determinant.remplacant }}</span>».
        </div>
        <div v-else>
          Le nom <span class="nom">{{ determinant.nomAssocie }}</span> n'a pas de déterminant associé.
        </div>
      </li>
    </ul>

    <div v-if="getDeterminantsList.filter(determinant => determinant.mot !== '').length != 0">
      <!-- On teste qu'au moins un déterminant a été sélectionné-->
      Remplacement :

      <span v-for="mot in listeMotsRegroupes()"
        :class="{ determinant: getDeterminantsList.map(determinant => determinant.remplacant).includes(motAffiche(mot)) }"
        :key="mot">
        {{ motAffiche(mot) }}
      </span>
    </div>
  </div>



  <div class="mt-2"> <!-- Adjectif -->
    <h3>Adjectif</h3>

    <div v-if="getAdjectifSelectionne().length != 0">

      Vous avez sélectionné les adjectifs suivants :

      <ul>
        <li v-for="adjectif in getAdjectifSelectionne()" :key="adjectif">
          <span class="adjectif"> {{ adjectif.motAdjectif }} </span> (adjectif <strong>{{ adjectif.type ?
            "qualifiant" :
            "classifiant" }} </strong>)
        </li>
      </ul>
    </div>
    <div v-else>
      Vous n'avez pas sélectionné d'adjectif.
    </div>
  </div>

  <div class="mt-2"> <!-- Pronom -->
    <h3>Pronom</h3>

    <div v-if="getPronomsList.length == 0">
      Vous n'avez pas sélectionné de pronom.
    </div>

    <div v-else>
      Vous avez sélectionné les pronoms suivants :

      <ul>
        <li v-for="pronom in getPronomsList" :key="pronom">
          <span class="pronom"> {{ pronom.motPronom }} </span> (peut être remplacé par le pronom <strong>{{
            pronom.pronomRemplacant }}</strong> ou le groupe nominal <strong>{{ pronom.phraseRemplacante }}</strong>)
        </li>
      </ul>
    </div>
  </div>

  <div class="mt-2"> <!-- Conjonction de coordination -->
    <h3>Conjonction de coordination</h3>

    <div v-if="getConjonctionsCoordList.length == 0">
      Vous n'avez pas sélectionné de conjonction de coordination.
    </div>

    <div v-else>
      Vous avez sélectionné les conjonctions de coordination suivantes :

      <ul>
        <li v-for="conjonctionCoord in getConjonctionsCoordList" :key="conjonctionCoord">
          <div v-if="conjonctionCoord.motConjonctionCoord != ''">
            <span class="conjonctionCoord"> {{ conjonctionCoord.motConjonctionCoord }} </span> (relie 2 <strong>{{
              conjonctionCoord.nature }}s</strong>).
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="mt-2"> <!-- Conjonction de subordination -->
    <h3>Conjonction de subordination</h3>

    <div v-if="getConjonctionsSubList.length == 0">
      Vous n'avez pas sélectionné de conjonction de subordination.
    </div>

    <div v-else>
      Vous avez sélectionné les conjonctions de subordination suivantes :

      <ul>
        <li v-for="conjonctionSub in getConjonctionsSubList" :key="conjonctionSub">
          <div v-if="conjonctionSub.motConjonctionSub != ''">
            <span class="conjonctionSub"> {{ conjonctionSub.motConjonctionSub }} </span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="mt-2"> <!-- Preposition -->
    <h3>Preposition</h3>

    <div v-if="getPrepositionsList.length == 0">
      Vous n'avez pas sélectionné de préposition.
    </div>

    <div v-else>
      Vous avez sélectionné les prépositions suivantes :

      <ul>
        <li v-for="preposition in getPrepositionsList" :key="preposition">
          <div v-if="preposition.motPreposition != ''">
            <span class="preposition"> {{ preposition.motPreposition }} </span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="mt-2"> <!-- Adverbe -->
    <h3>Adverbe</h3>

    <div v-if="getAdverbesList.length == 0">
      Vous n'avez pas sélectionné d'adverbe.
    </div>

    <div v-else>
      Vous avez sélectionné les adverbes suivants :

      <ul>
        <li v-for="adverbe in getAdverbesList" :key="adverbe">
          <div v-if="adverbe.motAdverbe != ''">
            <span class="adverbe"> {{ adverbe.motAdverbe }} </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["listeMotsRegroupes", "getNomSelectionne", "getDeterminantSelectionne", "getDeterminantsValues", "getAdjectifSelectionne", "getPronomSelectionne"],

  computed:
  {
    getDeterminantsList()
    {
      return this.$store.getters.getDeterminantsList;
    },

    getPronomsList()
    {
      return this.$store.getters.getPronomsList;
    },

    getConjonctionsCoordList()
    {
      return this.$store.getters.getConjonctionsCoordList;
    },

    getConjonctionsSubList()
    {
      return this.$store.getters.getConjonctionsSubList;
    },

    getPrepositionsList()
    {
      return this.$store.getters.getPrepositionsList;
    },

    getAdverbesList()
    {
      return this.$store.getters.getAdverbesList;
    }
  },

  methods: {
    motAffiche(mot)
    {
      if (mot === " ")
      {
        return "\u00a0";
      }
      else if (this.getDeterminantsList.some(d => d.mot === mot))
      {
        // Trouver l'objet dans la liste où "mot" correspond
        const determinant = this.getDeterminantsList.find(d => d.mot === mot);

        return determinant.remplacant || "____"; // quand l'utilisateur n'a pas encore rempli le champ, la valeur de la clef est 'null'
      } else
      {
        return mot;
      }
    }
  }
}
</script>



<style scoped>
.determinant {
  color: darksalmon;
}

.nom {
  color: rgb(155, 132, 5);
}

.adjectif {
  color: darkmagenta;
}


.pronom {
  color: red;
}

.conjonctionCoord {
  padding: 5px;
  margin: 0 40px;
  border-radius: 20px;
  border: 2px solid green;
  display: inline-block;
  position: relative;
}

.conjonctionCoord::before {
  content: "";
  position: absolute;
  /* Utilise une position absolue */
  left: -30px;
  /* Ajuste pour positionner les flèches à gauche */
  right: -30px;
  /* Ajuste pour positionner les flèches à droite */
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  background:
    /* arrow */
    linear-gradient(to bottom right, green 49%, transparent 52%) bottom right/15px 50%,
    linear-gradient(to top right, green 49%, transparent 52%) top right/15px 50%,
    /* circle */
    /* radial-gradient(green 56%, transparent 60%) -2px 50%/20px 20px, */
    /* line */
    linear-gradient(green, green) right center/30px 2px,
    linear-gradient(green, green) left center /30px 2px,
    /* arrow */
    linear-gradient(to bottom left, green 49%, transparent 52%) bottom left/15px 50%,
    linear-gradient(to top left, green 49%, transparent 52%) top left/15px 50%;
  background-repeat: no-repeat;
  z-index: 1;
  /* Assure la visibilité des flèches */
}

.conjonctionSub {
  color: green;
}

.preposition {
  text-decoration: underline double;
}

.adverbe {
  text-decoration: underline;
  text-decoration-style: wavy;
}
</style>