export default
    {
        getColorOptions(state)
        {
            return state.colorOptions;
        },

        getStyleOptions(state)
        {
            return state.styleOptions;
        },

        getVerbeClasses(state)
        {
            return state.verbeClasses;
        },
    }