<template>
  <p class="consigne">1 - Sélectionnez le COI.</p>

  <p>
    COI sélectionné : <span class="coi">{{ coiSelectionne }}</span>
  </p>

  <div v-if="coiSelectionne != ''" class="container">
    <p>
      C'est <span class="coi">{{ coiSelectionne }}</span> que <span v-for="mot in listeSansCoi" :key="mot">{{
        mot.toLowerCase()
        }}</span>
    </p>
  </div>

  <div v-if="etape === 2">
    <hr class="hr-style">
    <p class="consigne"> 2 - Choisissez le pronom qui convient pour remplacer <span class="coi">{{ coiSelectionne
        }}</span>.</p>
    <div class="button-pronoms">
      <the-button buttonType="pronom" v-for="pronom in listePronoms" :key="pronom" @click="choixPronom(pronom)">{{
        pronom }}</the-button>
    </div>
  </div>

  <div v-if="etape === 2" class="container">
    <p>
      <span v-for="(mot, indice) in listeSansCoi" :key="indice">
        {{ insererCoiAvantVerbe(mot) }}
      </span>
    </p>
  </div>

  <div class="button-container">
    <the-button v-if="etape === 1" buttonType="annuler" @click="annulationCoi">Annuler</the-button>
    <the-button v-if="etape === 1" buttonType="valider" @click="validationCoi">Valider</the-button>
    <the-button buttonType="suivant" @click="confirmer">Suivant</the-button>
  </div>
</template>

<script>
export default {
  emits: ["coiAnnulé", "coiValidé"],

  props: ['listeMots', 'verbePrincipal', 'sujetPrincipal'],

  data()
  {
    return {
      coiSelectionne: "",
      etape: 1,
      listeCoiRegroupe: [],
      listePronoms: ["Me", "M'", "Te", "T'", "Lui", "Elle", "Nous", "Vous", "Leur", "En"],
      pronomChoisi: "_____"
    };
  },

  computed: {
    listeSansCoi()
    {
      return this.listeCoiRegroupe.filter((element) => element != this.coiSelectionne);
    }
  },

  methods: {
    getHighlightedText()
    {
      const selection = window.getSelection();
      if (selection.rangeCount > 0)
      {
        const range = selection.getRangeAt(0);
        return range.toString();
      }
    },

    annulationCoi()
    {
      this.$emit("coiAnnulé", this.coiSelectionne);
      this.coiSelectionne = "";
    },

    validationCoi()
    {
      this.annulationCoi();
      this.coiSelectionne = this.getHighlightedText();
      this.listeCoiRegroupe = this.regrouperCoi(this.listeMots);
      this.$emit("coiValidé", this.coiSelectionne);
    },

    confirmer()
    {
      if (this.etape === 1)
      {
        this.etape = 2;
      }
      else if (this.etape === 2)
      {
        this.$emit('fin-etape', this.listeCoiRegroupe);
      }
    },

    longueurCoiSelectionne(chaine)
    {
      const nombreCoi = chaine.split(" ").length;

      return nombreCoi + (nombreCoi - 1);
    },

    regrouperCoi(liste)
    {
      let nouvelleListe = [...liste];

      // Séparer le sujet en mots
      let motsDuCoi = this.coiSelectionne.split(/\s+/);

      // Trouver l'index de la première occurrence des mots dans la nouvelle liste
      let index = nouvelleListe.findIndex((mot) => motsDuCoi.includes(mot));

      // Si les mots sont trouvés dans la liste, les remplacer par le verbe complet
      if (index !== -1)
      {
        nouvelleListe.splice(
          index,
          this.longueurCoiSelectionne(this.coiSelectionne),
          this.coiSelectionne
        );
      }

      return nouvelleListe;
    },

    choixPronom(pronom)
    {
      this.pronomChoisi = pronom;
    },

    insererCoiAvantVerbe(mot)
    {
      /*
      Si le mot est le verbe principal, rajoute le pronom choisi associé au COD devant. Sinon, renvoie simplement le mot sans le changer.
      */
      if (mot === this.verbePrincipal)
      {
        return this.pronomChoisi.toLowerCase() + ' ' + mot;
      }
      return mot
    }
  },
};
</script>

<style scoped>
.coi {
  color: rgb(151, 108, 13);
}

.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button.confirmer {
  font: inherit;
  border: 1px solid rgb(0, 0, 0);
  background-color: #770a41;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

button.confirmer:hover,
button.confirmer:active {
  border-color: rgb(0, 90, 27);
  background-color: #500029;
}

button.validationCoi {
  font: inherit;
  border: 1px solid rgb(0, 90, 27);
  background-color: rgb(31, 187, 26);
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.hr-style {
  margin: 0;
  /* Supprime la marge par défaut du hr */
  border: none;
  /* Supprime la bordure par défaut du hr */
  border-top: 1px solid #ccc;
  /* Ajoute une bordure personnalisée */
  width: calc(100% - 5rem);
  /* Ajustez la largeur du hr en fonction de vos besoins */
}
</style>