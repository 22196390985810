export default
    {
        verbesList: [],
        nomsList: [],
        determinantsList: [],
        pronomsList: [],
        conjonctionsCoordList: [],
        conjonctionsSubList: [],
        prepositionsList: [],
        adverbesList: [],
    }