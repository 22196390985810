<template>
    <v-dialog v-model="dialog" @click:outside="closeDialog" max-width="40%">
        <v-card>
            <v-card-title>Aide</v-card-title>
            <v-card-subtitle>Aide pour l'utilisation de GrammAnalyse.</v-card-subtitle>
            <v-card-text>
                A venir.
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="closeDialog" color="primary" dark>Valider</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    emits: ['valider'],

    props: ['open'],

    data()
    {
        return {
            dialog: this.open
        };
    },

    watch: {
        open(val)
        {
            // Mettre à jour le dialog lorsque open change
            this.dialog = val;
        }
    },

    methods: {
        closeDialog()
        {
            this.$emit('valider');
            this.dialog = false;
        }
    }
};
</script>

<style scoped>
/* Styles personnalisés pour le v-card et le v-dialog si nécessaire */
.v-card-title {
    background-color: #640032;
    color: white;
    padding: 1rem;
    font-size: 1.5rem;
}

.v-card-subtitle {
    padding: 1rem;
}
</style>