<template>
  <v-hover v-slot:default="{ isHovering, props }">
    <v-app-bar app color="#640032" dark v-bind="props" :elevation="isHovering ? 8 : 4">
      <v-container class="pa-0 fill-height" fluid>
        <v-toolbar :image="bannerImage">
          <v-row no-gutters align="center" justify="space-between">
            <v-col cols="auto">
              <v-btn icon @click="accueil" size="large">
                <v-icon class="toolbarElements" size="32">mdi-home</v-icon>
              </v-btn>
            </v-col>

            <v-col class="text-center">
              <v-toolbar-title class="text-h4 font-weight-bold toolbarElements"
                style="cursor: pointer; white-space: nowrap;" @click="accueil">
                <span class="title-highlight">GrammAnalyse</span>
              </v-toolbar-title>
            </v-col>

            <v-col cols="auto">
              <v-btn icon @click="showInfo" class="mr-2">
                <v-icon class="toolbarElements">mdi-information</v-icon>
              </v-btn>
              <!-- <v-btn icon @click="showSettings">
                <v-icon class="toolbarElements">mdi-cog</v-icon>
              </v-btn> -->
            </v-col>
          </v-row>
        </v-toolbar>
      </v-container>
    </v-app-bar>
  </v-hover>

  <!-- infoDialog Box -->
  <v-dialog v-model="infoDialog" max-width="40%">
    <v-card>
      <v-card-title class="headline">Information</v-card-title>
      <v-card-subtitle>
        <!-- Votre contenu d'information ici -->
        <p>Site réalisé par C. et L. Prabel.</p>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="infoDialog = false">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bannerImage from '../../../public/background.jpg'

export default {
  name: 'AppHeader',

  data()
  {
    return {
      bannerImage,
      infoDialog: false
    }
  },

  methods: {
    accueil()
    {
      // Logique pour retourner à l'accueil
      this.$emit('accueil');
    },
    showInfo()
    {
      this.infoDialog = true
    },
    showSettings()
    {
      // Logique pour afficher les paramètres
    }
  }
}
</script>

<style scoped>
.v-toolbar__title {
  transition: opacity 0.3s ease;
  letter-spacing: 1px;
}

.v-toolbar__title:hover {
  opacity: 0.8;
}

.toolbarElements {
  color: white;
}

.title-highlight {
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
  line-height: 1.2;
  /* Ajustez cette valeur si nécessaire */
  /* Text shadow to simulate border */
  text-shadow:
    0 0 2px rgb(0, 0, 0),
    0 0 2px rgb(0, 0, 0),
    0 0 2px rgb(0, 0, 0),
    0 0 2px rgb(0, 0, 0);
}

.title-highlight:hover {
  color: #ce0067;
  /* Optional: changes the text color on hover */
}
</style>