export default
    {
        methods:
        {
            getHighlightedText()
            {
                const selection = window.getSelection();
                if (selection.rangeCount > 0)
                {
                    const range = selection.getRangeAt(0);
                    return range.toString();
                }
            },


            enleverEspaces(chaine)
            {
                return chaine.replace(/\s+/g, '');
            },

            motInclusDansGroupe(phrase, indice, groupe)
            {
                // Vérifier si le mot phrase[indice] appartient à groupe en temps que membre de la phrase (donc en vérifiant aussi que son voisinage correspond).

                const motCible = phrase[indice];

                // Vérifier si le mot à l'indice existe dans le groupe
                if (!groupe.includes(motCible))
                {
                    return false;
                }

                // Calculer la longueur du groupe
                const groupeLength = groupe.length;

                // Chercher si le groupe apparaît dans la phrase à partir de l'indice
                // Nous devons chercher le groupe autour de l'indice dans la phrase
                for (let i = 0; i <= phrase.length - groupeLength; i++)
                {
                    if (phrase.slice(i, i + groupeLength).every((mot, j) => mot === groupe[j]))
                    {
                        // Vérifier si l'indice est dans la plage du groupe
                        if (i <= indice && indice < i + groupeLength)
                        {
                            return true;
                        }
                    }
                }

                // Si aucune correspondance n'est trouvée, retourner false
                return false;
            },

            chaineVersListe(str)
            {
                // Expression régulière pour capturer les mots et les séparateurs (espaces et apostrophes)
                const regex = /(\s+|')/g;
                // Utilisation de la méthode split avec la regex
                return str.split(regex).filter(Boolean); // Utiliser filter(Boolean) pour enlever les éléments vides
            }
        }
    }