<template>
    <p class="consigne">{{ etape }} - Sélectionnez le ou les verbes.</p>

    <p> Votre sélection : <slot name="selection-verbe"></slot>
    </p>
</template>

<script>
export default {
    props: ['etape']
}
</script>